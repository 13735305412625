import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getArticleUrl } from "utils/sections";

const FooterColumn = ({ title, sections }) => {
  return (
    <Col className="pt-4 pt-md-0" xs="6" md>
      <Row>
        <Col className="tc-blue-3 text-uppercase tfs-small font-weight-bold pb-3">
          {title}
        </Col>
      </Row>
      {sections.map((section, index) => (
        <Row className="pb-1" key={index}>
          <Col>
            <Link
              to={section.article ? getArticleUrl(section) : section.url}
              className="tc-white tfs-very-small thc-white"
            >
              {section.name}
            </Link>
          </Col>
        </Row>
      ))}
    </Col>
  );
};

export default FooterColumn;
