import config from "config";
import {
  API_PUBLICATION_TYPE_MAGAZINE,
  API_PUBLICATION_TYPE_PUBLICATION,
} from "api";

/**
 * Returns type value for publications API call
 * @param {string} categoryId
 * @returns {string|null}
 */
const getType = (categoryId) => {
  if (categoryId === "only") {
    return API_PUBLICATION_TYPE_PUBLICATION;
  }

  if (categoryId === "magazines") {
    return API_PUBLICATION_TYPE_MAGAZINE;
  }

  return null;
};

/**
 * Returns category id value for publication API call
 * @param {string} categoryId
 * @returns {number|null}
 */
const getCategory = (categoryId) => {
  const id = parseInt(categoryId);

  if (isNaN(id)) {
    return null;
  }

  return id;
};

/**
 * Returns page count for pagination
 * @param {number} publicationCount
 * @returns {number}
 */
const getPageCount = (publicationCount) => {
  return Math.ceil(publicationCount / config.PUBLICATION_PER_PAGE);
};

/**
 * Return paginated publication page URL
 * @param {number} pageId
 * @param {number} categoryId
 * @param {number} categorySlug
 * @returns {string}
 */
const getPublicationPageURL = (pageId, categoryId, categorySlug) => {
  return [
    "/publications/page",
    pageId,
    "category",
    categoryId,
    categorySlug,
  ].join("/");
};

/**
 * Returns URL path for publication on list after modal is closed
 * For example converts URL:
 *     /publications/page/1/category/1/category-slug/publication/16/publication-slug
 * To URL:
 *     /publications/page/1/category/1/category-slug
 * @param {string} pathname
 * @returns {string}
 */
const getPublicationListURL = (pathname) => {
  const splitted = pathname.split("/");
  const resized = splitted.slice(0, splitted.length - 3);
  return resized.join("/");
};

/**
 * Returns publication full "Issued at" text combined from multiple fields
 * @param {Object} data
 * @param {string} data.city
 * @param {string} data.publishing_house
 * @param {string} data.year
 * @param {string} data.pages
 * @returns {string}
 */
const getIssuedAtText = (data, pageCountText = "lpp.") => {
  const city = data.city ? `${data.city}:` : "";
  const publishing_house = data.publishing_house
    ? `${data.publishing_house},`
    : "";
  const year = data.year ? `${data.year}.` : "";
  const pages = data.pages ? `${data.pages} ${pageCountText}` : "";

  const items = [];

  [city, publishing_house, year, pages].forEach((t) => {
    if (t.length > 0) {
      items.push(t);
    }
  });

  return items.join(" ");
};

export {
  getType,
  getCategory,
  getPageCount,
  getPublicationPageURL,
  getPublicationListURL,
  getIssuedAtText,
};
