import { ImArrowLeft2 as LeftArrowIcon } from "react-icons/im";
import ArrowLink from "./ArrowLink";

const LeftArrowLink = ({ gallery, image }) => {
  return (
    <ArrowLink gallery={gallery} image={image}>
      <LeftArrowIcon
        className={
          "tfs-very-very-large ml-1 " + (image ? "tc-orange" : "tc-gray-2")
        }
      />
    </ArrowLink>
  );
};

export default LeftArrowLink;
