import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BiRightArrow as Icon } from "react-icons/bi";

const CategoryRow = ({
  handleSelect,
  category,
  isLast,
  handleGetUrl,
  handleIsSelected,
  children,
  show = true,
}) => {
  return (
    show && (
      <Row>
        <Col className={!isLast && "pb-3"}>
          <Icon
            className="tc-orange tfs-very-small mr-2"
            style={{ position: "relative", bottom: "2px" }}
          />
          <Link
            to={handleGetUrl(category)}
            onClick={() => handleSelect(category)}
            className={
              " " +
              (handleIsSelected(category)
                ? "font-weight-bold tc-orange"
                : "tc-black")
            }
          >
            {category.name}
          </Link>
          {children}
        </Col>
      </Row>
    )
  );
};

export default CategoryRow;
