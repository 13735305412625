import { Col, Container, Row, Spinner } from "react-bootstrap";

const BackgroundLoader = () => {
  return (
    <Container fluid className="bc-blue-4 h-100">
      <Row className="align-items-center h-100 text-center">
        <Col className="tc-white">
          <Spinner animation="border" />
        </Col>
      </Row>
    </Container>
  );
};

export default BackgroundLoader;
