import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const GAPageView = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);
  return null;
};

export default GAPageView;
