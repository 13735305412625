import { Link } from "react-router-dom";
import "./styles/ButtonOrange.scss";

const ButtonOrange = ({ to, children, customClasses, onClick, asPlain }) => {
  const Wrapper = ({ children, ...rest }) => {
    if (asPlain) {
      return (
        <a {...rest} href={to} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    }

    return <Link {...rest}>{children}</Link>;
  };

  return (
    <Wrapper
      to={to}
      className={
        "font-weight-bold text-uppercase tfs-small button-orange " +
        customClasses
      }
      onClick={onClick}
    >
      <span className="position-relative" style={{ top: "1px" }}>
        {children}
      </span>
    </Wrapper>
  );
};

export default ButtonOrange;
