import { useQuery } from "react-query";
import { apiAllPublications } from "api";
import Publications from "./Publications";
import { useContext } from "react";
import { ConfigContext } from "context";
import { API_PUBLICATION_TYPE_PUBLICATION } from "api";
import T from "locales";

const PUBLICATION_COUNT = 8;

const NewestPublications = () => {
  const { language } = useContext(ConfigContext);

  const { isLoading, error, data } = useQuery(
    ["newestPublications", language],
    () =>
      apiAllPublications({
        language,
        type: API_PUBLICATION_TYPE_PUBLICATION,
        count: PUBLICATION_COUNT,
      })
  );

  return (
    <Publications
      publications={data}
      error={error}
      isLoading={isLoading}
      customCategoryName={<T k="publication_newest_publications" />}
      isSmallMd
    />
  );
};

export default NewestPublications;
