import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import { Modal } from "react-bootstrap";

const ImageModal = ({ image, show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LazyLoadImg
          image={image.thumbnails.large}
          alt={
            image.description ||
            image.image.default_alt_text ||
            image.image.name
          }
        />
      </Modal.Body>
      <Modal.Footer>{image.description}</Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
