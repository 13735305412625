import { Alert } from "react-bootstrap";

const ErrorAlert = ({ message, classNames }) => {
  return (
    <Alert variant="danger" className={"text-center mb-0 " + classNames}>
      {message}
    </Alert>
  );
};

export default ErrorAlert;
