import ButtonOrange from "components/button/ButtonOrange";
import RichText from "components/richtext/RichText";
import T from "locales";
import { getPublicationPageURL } from "pages/publications/utils";
import { Col, Row } from "react-bootstrap";

const CategorySection = ({ category, section }) => {
  return (
    <>
      <Row className="pt-5 px-3">
        <h2 className="my-auto">{category?.name}</h2>
        <ButtonOrange
          customClasses="ml-auto d-none d-sm-block"
          to={getPublicationPageURL(1, category.id, category.slug)}
        >
          <T k="publication_view" />
        </ButtonOrange>
      </Row>
      <Row>
        <Col>
          <hr className="my-4" />
          <h4>{section.name}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <RichText text={section.text} />
        </Col>
      </Row>
    </>
  );
};

export default CategorySection;
