import { apiGalleries } from "api";
import { WhiteContainer } from "components/container";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import PageLoader from "components/page-loader/PageLoader";
import PageTitle from "components/page_title/PageTitle";
import { ConfigContext } from "context";
import T from "locales";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import "./styles/GalleryPage.scss";

const GalleryPage = () => {
  const { language } = useContext(ConfigContext);

  const { isLoading, error, data } = useQuery(["galleries", language], () =>
    apiGalleries(language)
  );

  const getUrl = (gallery) => {
    return ["/gallery", gallery.id, gallery.slug].join("/");
  };

  return (
    <>
      <PageTitle title={<T k="gallery_title" />} />

      <PageLoader isLoading={isLoading} error={error} />

      {data && (
        <WhiteContainer>
          <Row className="py-5">
            {data.map((gallery) => (
              <Col md="4" className="pb-3" key={gallery.id}>
                <Link
                  to={getUrl(gallery)}
                  className="text-decoration-none gallery-link"
                >
                  <LazyLoadImg
                    image={gallery.thumbnails.small}
                    alt={gallery.image.default_alt_text || gallery.image.name}
                  />
                  <div className="gallery-label bc-orange w-75 tc-white p-2 tfs-small ml-2">
                    {gallery.name}
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </WhiteContainer>
      )}
    </>
  );
};

export default GalleryPage;
