import ButtonOrange from "components/button/ButtonOrange";
import DottedLoader from "components/dotted-loader/DottedLoader";
import ErrorAlert from "components/error-alert/ErrorAlert";
import Modal from "components/modal/Modal";
import PublicationCardDeck from "components/publication-cards/PublicationCardDeck";
import RichText from "components/richtext/RichText";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";

const Publications = ({
  publications,
  category,
  isLoading,
  error,
  isSmallMd,
  customCategoryName,
}) => {
  const [showAuthorNoteModal, setShowAuthorNoteModal] = useState(false);

  const categoryName = () => {
    return customCategoryName || category?.name || null;
  };

  if (isLoading || error) {
    return (
      <div className="pt-5">
        {categoryName() && <h2 className="my-auto pb-5">{categoryName()}</h2>}
        {isLoading && (
          <div className={error && "mb-5"}>
            <DottedLoader />
          </div>
        )}
        {error && <ErrorAlert message={error.message} />}
      </div>
    );
  }

  return (
    <>
      <Row className="py-5 px-3">
        <h2 className="my-auto">{categoryName()}</h2>
        {category?.button_text && (
          <ButtonOrange
            customClasses="ml-auto d-none d-sm-block"
            onClick={() => setShowAuthorNoteModal(true)}
            to="#authorNoteModal"
          >
            {category?.button_text}
          </ButtonOrange>
        )}
      </Row>
      {category?.short_description && (
        <Row>
          <Col className="pb-5">
            <RichText text={category?.short_description} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <PublicationCardDeck
            publications={publications}
            isSmallMd={isSmallMd}
            insertEmptyCards
          />
        </Col>
      </Row>
      <Modal
        show={showAuthorNoteModal}
        handleClose={() => setShowAuthorNoteModal(false)}
        title={category?.button_text}
      >
        <RichText text={category?.long_description} />
      </Modal>
    </>
  );
};

export default Publications;
