import ButtonOrange from "components/button/ButtonOrange";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import { AiOutlineArrowRight as RightArrow } from "react-icons/ai";
import "./styles.scss";

const Banner = ({ item }) => {
  const getTextStyle = () => {
    return {
      1: "white",
      2: "black",
      3: "blue",
    }[item.text_style];
  };

  return (
    <>
      <LazyLoadImg
        image={item.image.url}
        alt={item.image.default_alt_text || item.image.name}
      />

      {item.text && (
        <div className={`banner-text ${getTextStyle()}`}>{item.text}</div>
      )}

      {item.cta_text && (
        <div className="banner-cta">
          <ButtonOrange to={item.cta_url} asPlain customClasses="p-3">
            {item.cta_text} <RightArrow />
          </ButtonOrange>
        </div>
      )}
    </>
  );
};

export default Banner;
