import { Col, Row } from "react-bootstrap";
import { ReactComponent as Logo } from "assets/logo/large.svg";
import Navigation from "./Navigation";
import routes from "pages/routes";
import { Link } from "react-router-dom";
import HeaderInfo from "./HeaderInfo";
import useWindowSize from "effects/useWindowSize";
import { BlueContainer } from "components/container";
import MobileHeader from "./MobileHeader";

const Header = () => {
  const { isTablet, isMobile } = useWindowSize();

  if (isTablet || isMobile) {
    return <MobileHeader />;
  }

  return (
    <BlueContainer>
      <Row className="py-4">
        <Col xs="4" className="pt-2">
          <Link to={routes.home}>
            <Logo />
          </Link>
        </Col>
        <Col>
          <Row>
            <Col>
              <HeaderInfo />
            </Col>
          </Row>
          <Row>
            <Col>
              <Navigation />
            </Col>
          </Row>
        </Col>
      </Row>
    </BlueContainer>
  );
};

export default Header;
