import { Dropdown } from "react-bootstrap";
import { VscTextSize as FontSizeIcon } from "react-icons/vsc";
import config from "config";
import { useContext } from "react";
import { ConfigContext } from "context";

const FontSizeDropdown = ({ sizeClass }) => {
  const { font_size, configDispatch } = useContext(ConfigContext);

  const dropdownItems = config.FONT_SIZES.map((item, index) => (
    <Dropdown.Item
      key={index}
      className={
        "p-2 navigation-item-dropdown " + (item === font_size ? "active" : "")
      }
      onClick={() => configDispatch({ type: "change_font", payload: item })}
    >
      {item}%
    </Dropdown.Item>
  ));

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle className={"btn-nav tc-gray-3 " + sizeClass}>
          <FontSizeIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight className="rounded-0 p-3">
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default FontSizeDropdown;
