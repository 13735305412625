import { Link } from "react-router-dom";

const ArrowLink = ({ gallery, image, children }) => {
  const getImageUrl = (image) => {
    return ["/gallery", gallery.id, gallery.slug, "image", image.id].join("/");
  };

  if (!image) {
    return children;
  }

  return <Link to={getImageUrl(image)}>{children}</Link>;
};

export default ArrowLink;
