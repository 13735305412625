/**
 * Set value on localStorage by key
 * @param {string} key
 * @param {string} value
 */
const setOnStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (exception) {}
};

/**
 * Returns value from localStorage by key
 * @param {string} key
 * @returns {string}
 */
const getFromStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (exception) {
    return null;
  }
};

/**
 * Deletes items from local storage
 * @param {string} key
 */
const removeFromStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (exception) {}
};

export { setOnStorage, getFromStorage, removeFromStorage };
