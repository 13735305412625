const SUB_SECTION_LEFT = 1;
const SUB_SECTION_TOP = 2;
const SUB_SECTION_BOXES = 3;

/**
 * Returns boolean values for article sub-section placement
 * @param {number} subSectionPlacementID
 * @returns {Object} placement
 * @returns {Object} placement.isSubSectionLeft
 * @returns {Object} placement.isSubSectionTop
 */
const getSubSectionPlacement = (subSectionPlacementID) => {
  const isSubSectionLeft = subSectionPlacementID === SUB_SECTION_LEFT;
  const isSubSectionTop = subSectionPlacementID === SUB_SECTION_TOP;
  const isSubSectionBoxes = subSectionPlacementID === SUB_SECTION_BOXES;

  return { isSubSectionLeft, isSubSectionTop, isSubSectionBoxes };
};

/**
 * Finds article by ID in MTPP tree and returns it with list of direct parents
 * @param {Array} articles
 * @param {number} articleId
 * @param {Array} prevValues
 * @returns {Object|null}
 */
const getArticleById = (articles, articleId, prevValues = []) => {
  let values = prevValues;

  for (let article of articles) {
    if (article.id === parseInt(articleId)) {
      values.push(article);

      return {
        article: article,
        tree: values,
      };
    }

    if (article.children.length > 0) {
      values.push(article);
      const data = getArticleById(article.children, articleId, values);

      if (data !== null) {
        return {
          article: data.article,
          tree: data.tree,
        };
      } else {
        values.pop();
      }
    }
  }

  return null;
};

export { getSubSectionPlacement, getArticleById };
