import T from "locales";
import routes from "pages/routes";

const OTHER_SECTIONS = [
  {
    name: <T k="archive" />,
    url: routes.archive,
  },
  {
    name: <T k="news" />,
    url: routes.news,
  },
  {
    name: <T k="event" />,
    url: routes.events,
  },
];

const PUBLICATION_SECTIONS = [
  {
    name: <T k="publication_newses" />,
    url: "/publications",
  },
  {
    name: <T k="publication_all" />,
    url: "/publications/only",
  },
  {
    name: <T k="publication_all_magazines" />,
    url: "/publications/magazines",
  },
];

export { OTHER_SECTIONS, PUBLICATION_SECTIONS };
