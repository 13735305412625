import PageTitle from "components/page_title/PageTitle";
import { WhiteContainer } from "components/container";
import { Row, Col } from "react-bootstrap";
import Section from "./Section";
import img1 from "./images/1.jpg";
import img2 from "./images/2.png";
import img3 from "./images/3.png";
import img4 from "./images/4.png";
import T from "locales";
import "./styles.scss";

const AboutUsPage = () => {
  return (
    <>
      <PageTitle
        title={<T k="about_us_title" />}
        desc={<T k="about_us_desc" />}
      />
      <WhiteContainer className="about-us mt-5">
        <Section
          ltr={true}
          pdf={false}
          img={img1}
          textRange={[1, 4]}
          imgTitleNr={1}
          hasLargeImage
        />
        <Row className="my-5">
          <Col xs="12">
            <h2>
              <T k="about_us_history_title" />
            </h2>
          </Col>
        </Row>
        <Section
          ltr={false}
          pdf={false}
          img={img2}
          textRange={[5, 7]}
          imgTitleNr={2}
        />
        <Section
          ltr={true}
          pdf={false}
          img={img3}
          textRange={[8, 9]}
          imgTitleNr={3}
        />
        <Section
          ltr={false}
          pdf={false}
          img={img4}
          textRange={[10, 11]}
          imgTitleNr={4}
        />
      </WhiteContainer>
    </>
  );
};

export default AboutUsPage;
