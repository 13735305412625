import { Row, Col } from "react-bootstrap";
import { useContext } from "react";
import { useQuery } from "react-query";
import { ConfigContext } from "context";
import { apiLatestEvents } from "api";
import { seperateDate } from "utils/dateHelpers";
import T from "locales";
import "./styles.scss";
import DottedLoader from "components/dotted-loader/DottedLoader";
import { Link } from "react-router-dom";
import ErrorAlert from "components/error-alert/ErrorAlert";

const LATEST_EVENT_COUNT = 4;

const Items = () => {
  const { language } = useContext(ConfigContext);

  const { isLoading, error, data } = useQuery(
    ["latestEvents", language, LATEST_EVENT_COUNT],
    () => apiLatestEvents(language, LATEST_EVENT_COUNT)
  );

  const renderEventName = (title) => {
    return (
      <p className="mt-2 mb-2 tfs-large font-weight-bold event-title">
        {title}
      </p>
    );
  };

  const getUrl = (item) => {
    return ["events", item.id, item.slug].join("/");
  };

  if (isLoading) {
    return (
      <Col className="border-0 bg-transparent">
        <DottedLoader />
      </Col>
    );
  }

  if (error) {
    return (
      <Col>
        <ErrorAlert message={error.message} />
      </Col>
    );
  }

  return data.map((item) => (
    <Col md="6" key={item.id} className="mb-4">
      <Row>
        <Col xs="4" sm="3">
          <div style={{ maxWidth: "80px" }} className="text-center">
            <p className="month m-0 tfs-large">
              <T k={seperateDate(item.date)[1]} />
            </p>
            <p className="day m-0 tfs-very-very-large">
              {seperateDate(item.date)[2] + "."}
            </p>
            <p className="year m-0 tfs-large">{seperateDate(item.date)[0]}</p>
          </div>
        </Col>
        <Col>
          {item.has_text && (
            <Link to={getUrl(item)}>{renderEventName(item.title)}</Link>
          )}
          {!item.has_text && renderEventName(item.title)}
          <p className="tfs-small tc-orange mb-0">{item.type.name}</p>
        </Col>
      </Row>
    </Col>
  ));
};

export default Items;
