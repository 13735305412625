import { apiNewsList } from "api";
import { WhiteContainer } from "components/container";
import NewsCards from "components/news-cards/NewsCards";
import PageTitle from "components/page_title/PageTitle";
import { ConfigContext } from "context";
import T from "locales";
import { useContext } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import { useQuery } from "react-query";
import { BsArrowRight as ArrowRight } from "react-icons/bs";
import { BsArrowLeft as ArrowLeft } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import { reverseDate } from "utils/dateHelpers";

const CURRENT_YEAR = new Date().getFullYear();

const NewsListPage = () => {
  const { year = CURRENT_YEAR } = useParams();
  const { language } = useContext(ConfigContext);
  const routerHistory = useHistory();

  const { isLoading, error, data } = useQuery(
    ["newsList", language, year],
    () => apiNewsList({ language, year })
  );

  const getUrl = (item) => {
    // If slug is not returned placeholder value has to be passed
    return ["/news", item.id, item.slug || "none"].join("/");
  };

  const getYearText = () => {
    if (parseInt(year) === CURRENT_YEAR) {
      return <T k="news_this_year" />;
    }

    return (
      <>
        <T k="news_title" /> {year}
      </>
    );
  };

  const onYearChange = (yearIncrement) => {
    routerHistory.push(["/news", parseInt(year) + yearIncrement].join("/"));
  };

  return (
    <>
      <PageTitle title={<T k="news_title" />} desc={year} />
      <WhiteContainer>
        <Row className="pt-5">
          <Col>
            <h2 className="pl-lg-5">
              <ArrowLeft
                className="tc-orange cursor-pointer mr-3 mb-1"
                onClick={() => onYearChange(-1)}
              />
              {getYearText()}
              {year < CURRENT_YEAR && (
                <ArrowRight
                  className="tc-orange cursor-pointer ml-3 mb-1"
                  onClick={() => onYearChange(1)}
                />
              )}
            </h2>
          </Col>
        </Row>
        <Row className="py-5">
          <Col>
            <NewsCards
              isLoading={isLoading}
              data={data}
              error={error}
              handleGetUrl={getUrl}
              handleGetDate={(item) => reverseDate(item.date)}
            />
            {data?.length === 0 && (
              <Alert variant="secondary">
                <T k="no_entries_this_year" />
              </Alert>
            )}
          </Col>
        </Row>
      </WhiteContainer>
    </>
  );
};

export default NewsListPage;
