const routes = {
  home: "/",
  article: "/article/:articleId(\\d+)/:articleSlug",
  sub_article:
    "/article/:articleId(\\d+)/:articleSlug/:subArticleId(\\d+)/:subArticleSlug",
  publications: "/publications",
  publications_category_page:
    "/publications/page/:pageId?/category/:categoryId?/:categorySlug?",
  publications_section_page:
    "/publications/category/:categoryId?/:categorySlug?/:sectionId?/:sectionSlug?",
  publications_detail:
    "/publications/category/:categoryId/:categorySlug/publication/:publicationId(\\d+)/:publicationSlug",
  publications_detail_page:
    "/publications/page/:pageId?/category/:categoryId/:categorySlug/publication/:publicationId(\\d+)/:publicationSlug",
  archive: "/article/203/arhivs",
  news: "/news",
  news_year: "/news/:year(\\d+)",
  news_detail: "/news/:newsId(\\d+)/:newsSlug",
  about_us: "/about_us",
  contact: "/contact",
  events: "/events",
  events_year: "/events/:year(\\d+)",
  event_detail: "/events/:eventId(\\d+)/:eventSlug",
  gallery: "/gallery",
  gallery_detail: "/gallery/:galleryId(\\d+)/:gallerySlug",
  gallery_detail_image:
    "/gallery/:galleryId(\\d+)/:gallerySlug/image/:imageId(\\d+)",
  personnel: "/personnel",
  admin: "/admin",
  search: "/search",
};

export default routes;
