/**
 * Maps object
 * @param {Object} obj
 * @param {function(value, key, index)} fn
 * @returns {Object}
 */
const mapObject = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export { mapObject };
