import { Modal as BootstrapModal } from "react-bootstrap";

const Modal = ({ show, title, footer, handleClose, size = "lg", children }) => {
  return (
    <BootstrapModal show={show} onHide={handleClose} size={size}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
      {footer && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
};

export default Modal;
