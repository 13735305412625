import JelenaNesatajevaImg from "assets/persons/jelena_nesatajeva.jpg";
import JelenaNesatajevaLargeImg from "assets/persons/jelena_nesatajeva_large.jpg";

import GeorgijsKonkovsImg from "assets/persons/georgijs_konkovs.jpg";
import GeorgijsKonkovsLargeImg from "assets/persons/georgijs_konkovs_large.jpg";

const CONTACT_PERSONS = [
  {
    name: "Jeļena Ņešatajeva",
    title: "Izpilddirektors",
    note: "Kab. 324",
    image: JelenaNesatajevaImg,
    largeImage: JelenaNesatajevaLargeImg,
  },
  {
    name: "Georgijs Koņkovs",
    title: "Lietvedis",
    note: "Tālr: +371 7034861",
    image: GeorgijsKonkovsImg,
    largeImage: GeorgijsKonkovsLargeImg,
  },
];

export { CONTACT_PERSONS };
