const TEXT_ERROR =
  "Notika tehniska kļūda. Izstrādātāji ir saņēmuši par šo paziņojumu un drīz veiks labojumus.";

const TEXT_LINK = "Atgriezties uz sākumu";

const ErrorSentry = () => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  return (
    <div className="w-75 m-auto">
      <div className="mt-5 alert alert-danger text-center">
        {TEXT_ERROR}
        <br />
        <a href="/" className="d-block mt-3">
          {TEXT_LINK}
        </a>
      </div>
    </div>
  );
};

export default ErrorSentry;
