import { WhiteContainer } from "components/container";
import PageTitle from "components/page_title/PageTitle";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { ConfigContext } from "context";
import { useContext, useEffect, useState } from "react";
import { apiArticle, apiArticleChildrenImages } from "api";
import { useParams } from "react-router-dom";
import PageLoader from "components/page-loader/PageLoader";
import DottedLoader from "components/dotted-loader/DottedLoader";
import RichText from "components/richtext/RichText";
import { getSubSectionPlacement, getArticleById } from "./utils";
import ErrorAlert from "components/error-alert/ErrorAlert";
import SubSections from "./components/SubSections";
import Breadcrumbs from "./components/Breadcrumbs";
import Tags from "./components/Tags";
import useWindowSize from "effects/useWindowSize";
import NewsCards from "components/news-cards/NewsCards";

const ArticlePage = () => {
  const { articleId, articleSlug, subArticleId } = useParams();
  const { language } = useContext(ConfigContext);
  const { isMobile, isTablet } = useWindowSize();
  const [subArticleChildren, setSubArticleChildren] = useState([]);
  const [showLeftNavigation, setShowLeftNavigation] = useState(false);
  const [showNavigationAsBoxes, setShowNavigationAsBoxes] = useState(false);
  const [showTopNavigation, setShowTopNavigation] = useState(false);
  const [subArticleTree, setSubArticleTree] = useState([]);
  const [subMenuHeadingText, setSubMenuHeadingText] = useState("");

  const {
    isLoading: isLoadingArticle,
    error: articleError,
    data: articleData,
  } = useQuery(["article", language, articleId], () =>
    apiArticle(language, articleId)
  );

  const {
    isLoading: isLoadingSubArticle,
    error: subArticleError,
    data: subArticleData,
  } = useQuery(["article-no-children", language, subArticleId], () => {
    if (!subArticleId) return;
    return apiArticle(language, subArticleId, true);
  });

  const { data: articleChildrenImagesData } = useQuery(
    ["article-children-images", language, subArticleId || articleId],
    () => apiArticleChildrenImages(language, subArticleId || articleId)
  );

  useEffect(() => {
    const { isSubSectionLeft, isSubSectionTop, isSubSectionBoxes } =
      getSubSectionPlacement(articleData?.sub_section_placement);
    const hasChildren = articleData?.children?.length > 0;

    setShowLeftNavigation(hasChildren && isSubSectionLeft);
    setShowTopNavigation(hasChildren && isSubSectionTop);
    setShowNavigationAsBoxes(hasChildren && isSubSectionBoxes);
  }, [articleData]);

  useEffect(() => {
    // Don't reset data while fetching sub-article
    if (!articleData || isLoadingSubArticle) {
      return;
    }

    // Reset breadcrumbs and sub-articles subsections if sub-article haven't been selected
    if (!subArticleData) {
      setSubArticleTree([]);
      setSubArticleChildren([]);
      return;
    }

    const { article, tree } = getArticleById(
      articleData.children,
      subArticleData.id
    );

    setSubArticleTree(tree);

    // If sub-article has sub-sections set those
    if (article.children.length > 0) {
      setSubArticleChildren(article.children);
      setSubMenuHeadingText(article.name);
    } else {
      // If sub article has no sub-sections keep parent article sub-sections
      if (tree.length >= 2) {
        const previousArticle = tree[tree.length - 2];
        setSubArticleChildren(previousArticle.children);
        setSubMenuHeadingText(previousArticle.name);
      }
    }
  }, [subArticleData, articleData, isLoadingSubArticle]);

  /**
   * Show main navigation if either
   *  1) No sub-article has been selected
   *  2) If sub-article has been selected but it has no child elements
   * @returns {boolean}
   */
  const showMainNavigation = () => {
    if (articleData?.children.length === 0) {
      return false;
    }

    return subArticleTree.length < 2 && subArticleChildren?.length === 0;
  };

  /**
   * Determines if sub-menu should be shown
   * @returns {boolean}
   */
  const showSubMenu = () => {
    return subArticleChildren?.length > 0;
  };

  /**
   * Determines if sub-menu should be full width
   * @returns {boolean}
   */
  const isSubMenuLarge = () => {
    const { isSubSectionTop } = getSubSectionPlacement(
      subArticleData?.sub_section_placement
    );

    return isSubSectionTop;
  };

  /**
   * Determines if sub-menu should be shown as boxes
   * @returns {boolean}
   */
  const isSubMenuBoxes = () => {
    const { isSubSectionBoxes } = getSubSectionPlacement(
      subArticleData?.sub_section_placement
    );

    return isSubSectionBoxes;
  };

  /**
   * Returns CSS class for article name top padding
   * @returns {string}
   */
  const getNameTopPadding = () => {
    if (!showMainNavigation() && !showSubMenu()) {
      return "pt-4";
    }

    if (showMainNavigation() && (showTopNavigation || !subArticleData)) {
      return "pt-4";
    }

    if (subArticleData) {
      if (isSubMenuLarge()) {
        return "pt-4";
      }

      if (showMainNavigation() && (isMobile || isTablet)) {
        return "pt-4";
      }

      return "";
    }

    if (showTopNavigation) {
      return "pt-4";
    }

    return "";
  };

  /**
   * Return realtive sub-article url
   * @param {Object} article
   * @returns {string}
   */
  const getUrl = (article) => {
    return ["/article", articleId, articleSlug, article.id, article.slug].join(
      "/"
    );
  };

  /**
   * Maps and returns data for News card from article data
   * @returns {Array}
   */
  const getBoxesSubArticleData = (children) => {
    return children.map((article) => {
      // Find image from separately fetched children images
      const image = articleChildrenImagesData?.find((a) => a.id === article.id);

      return {
        id: article.id,
        thumbnails: image?.thumbnails,
        image: image?.image,
        title: article.name,
        slug: article.slug,
        short_description: article.long_title,
      };
    });
  };

  return (
    <>
      <PageTitle
        title={articleData?.name || <DottedLoader />}
        largeDescriptionPadding={
          showMainNavigation() && subArticleTree.length === 0
        }
      />

      <PageLoader isLoading={isLoadingArticle} error={articleError} />

      {articleData && (
        <WhiteContainer className="pb-5">
          {subArticleTree.length > 0 && (
            <Row>
              <Col className="pt-3">
                <Breadcrumbs
                  article={articleData}
                  subArticles={subArticleTree}
                />
              </Col>
            </Row>
          )}

          <Row>
            {/* Main menu on left side or full width */}
            {showMainNavigation() && (showLeftNavigation || showTopNavigation) && (
              <Col lg={showLeftNavigation ? 3 : 12}>
                <SubSections
                  articles={articleData.children}
                  moveNavigationUp={subArticleTree.length === 0}
                  showToggle={isMobile || isTablet || !showLeftNavigation}
                  headingText={
                    (isMobile || isTablet || !showLeftNavigation) &&
                    articleData.name
                  }
                  canShowLongTitle
                />
              </Col>
            )}

            {/* Main menu as boxes */}
            {showMainNavigation() && showNavigationAsBoxes && !subArticleId && (
              <Col xs="12" className="pt-5 px-4">
                <NewsCards
                  data={getBoxesSubArticleData(articleData.children)}
                  handleGetUrl={getUrl}
                />
              </Col>
            )}

            {/* Sub-menu on left side or full width */}
            {showSubMenu() && !isSubMenuBoxes() && (
              <Col lg={isSubMenuLarge() ? 12 : 3} className="pb-4">
                <SubSections
                  articles={subArticleChildren}
                  canShowLongTitle
                  moveNavigationUp={false}
                  headingText={subMenuHeadingText}
                  showToggle={isMobile || isTablet}
                />
              </Col>
            )}

            {/* Sub-menu as boxes */}
            {showSubMenu() && isSubMenuBoxes() && (
              <Col xs="12" className="pt-4 px-4">
                <NewsCards
                  data={getBoxesSubArticleData(subArticleChildren)}
                  handleGetUrl={getUrl}
                />
              </Col>
            )}

            <Col
              className={`${
                showMainNavigation() || showSubMenu() ? "pl-4" : ""
              }`}
            >
              <Row>
                <Col className={getNameTopPadding()}>
                  {isLoadingSubArticle && <DottedLoader className="pt-4" />}

                  {subArticleError && (
                    <ErrorAlert message={subArticleError.message} />
                  )}

                  {!subArticleError && (
                    <>
                      {subArticleData?.name && !subArticleData.hide_title && (
                        <>
                          <h2>{subArticleData?.name}</h2>
                          <hr />
                        </>
                      )}
                      {!isLoadingSubArticle && subArticleData?.text && (
                        <>
                          <RichText text={subArticleData?.text} />
                          <Tags tags={subArticleData?.tags} />
                        </>
                      )}
                    </>
                  )}

                  {!subArticleId && articleData.text && (
                    <>
                      <RichText text={articleData.text} />
                      <Tags tags={articleData?.tags} />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </WhiteContainer>
      )}
    </>
  );
};

export default ArticlePage;
