import { useEffect, useReducer } from "react";
import default_config from "config";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ConfigContext } from "context";
import PageRoutesSwitch from "pages";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import { apiSections } from "api";
import { getSectionItems, storeSectionItems } from "utils/sections";
import { setOnStorage } from "utils/storage";
import { getLanguageKey, getLocales, LANGUAGE_CACHE_KEY } from "locales/utils";
import configReducer from "reducers/configReducer";
import ScrollToTop from "components/main/ScrollToTop";
import Main from "components/main/Main";
import BackgroundLoader from "components/background-loader/BackgroundLoader";
import Cookies from "components/cookies/Cookies";
import GAPageView from "components/ga-page-view/GAPageView";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: default_config.API_CACHE_EXPIRATION,
    },
  },
});

const INITIAL_LANGUAGE_KEY = getLanguageKey();
const INITIAL_LOCALES = getLocales(INITIAL_LANGUAGE_KEY);

const App = () => {
  const [{ language, font_size, sections, locales }, configDispatch] =
    useReducer(configReducer, {
      language: INITIAL_LANGUAGE_KEY,
      font_size: default_config.DEFAULT_FONT_SIZE,
      sections: null,
      locales: INITIAL_LOCALES,
    });

  // Remove initial hard-coded body styles
  useEffect(() => {
    document.body.style = "";
  }, []);

  // Perform global changes on language change
  useEffect(() => {
    // Load sections data from cache or pull from API
    const items = getSectionItems(language);

    if (!items) {
      configDispatch({ type: "set_sections", payload: null });

      apiSections(language).then((data) => {
        configDispatch({ type: "set_sections", payload: data });
        storeSectionItems(data, language);
      });
    } else {
      configDispatch({ type: "set_sections", payload: items });
    }

    // Store current language on storage
    setOnStorage(LANGUAGE_CACHE_KEY, language);

    // Update current locales
    configDispatch({ type: "set_locales", payload: getLocales(language) });
  }, [language]);

  // Show full-screen loader while fetching sections data
  if (!sections) {
    return <BackgroundLoader />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <GAPageView />
        <ScrollToTop />

        <ConfigContext.Provider
          value={{ language, font_size, sections, locales, configDispatch }}
        >
          <Main>
            <Header />
            <PageRoutesSwitch />
            <Cookies />
          </Main>

          <Footer />
        </ConfigContext.Provider>
      </Router>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
