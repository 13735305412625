import { useContext } from "react";
import { ConfigContext } from "context";
import NavigationSectionItem from "./NavigationSectionItem";

const Navigation = () => {
  const { sections } = useContext(ConfigContext);

  return (
    <nav className="mt-4">
      <ul className="flat-list float-right">
        {sections.map((section, index) => (
          <li key={section.id} className={index > 0 ? "ml-2" : ""}>
            <NavigationSectionItem section={section} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
