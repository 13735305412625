// List with locales/T.js variable that contains static text for home-main activities section list

const Info = {
  ssrList: [
    { text: "main_activities_ssr_text_1" },
    { text: "main_activities_ssr_text_2" },
    { text: "main_activities_ssr_text_3" },
    { text: "main_activities_ssr_text_4" },
    { text: "main_activities_ssr_text_5" },
    { text: "main_activities_ssr_text_6" },
    {
      text: "main_activities_ssr_text_7",
    },
  ],
  prList: [
    { text: "main_activities_fr_text_1" },
    {
      text: "main_activities_fr_text_2",
    },
    { text: "main_activities_fr_text_3" },
  ],
};

export default Info;
