import { useQuery } from "react-query";
import { apiAllPublications } from "api";
import Publications from "./Publications";
import { useContext } from "react";
import { ConfigContext } from "context";
import { API_PUBLICATION_TYPE_MAGAZINE } from "api";
import T from "locales";

const MAGAZINES_COUNT = 8;

const NewestMagazines = () => {
  const { language } = useContext(ConfigContext);

  const { isLoading, error, data } = useQuery(
    ["newestMagazines", language],
    () =>
      apiAllPublications({
        language,
        type: API_PUBLICATION_TYPE_MAGAZINE,
        count: MAGAZINES_COUNT,
      })
  );

  return (
    <Publications
      publications={data}
      error={error}
      isLoading={isLoading}
      customCategoryName={<T k="publication_newest_magazines" />}
      isSmallMd
    />
  );
};

export default NewestMagazines;
