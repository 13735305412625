import { Container } from "react-bootstrap";

const DarkBlueContainer = ({ children, className, ...rest }) => {
  return (
    <Container fluid className={"bc-blue-5 p-0 " + className} {...rest}>
      <Container>{children}</Container>
    </Container>
  );
};

export default DarkBlueContainer;
