import { useEffect, useState } from "react";

const TABLET_END_WIDTH = 1100;
const MOBILE_END_WIDTH = 768;
const SMALL_DESKTOP_END_WIDTH = 1500;

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  // Every width under MD
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_END_WIDTH
  );

  // Every width between MD and LG
  const [isTablet, setIsTablet] = useState(
    window.innerWidth > MOBILE_END_WIDTH && window.innerWidth < TABLET_END_WIDTH
  );

  // Every width between LG and XL
  const [isSmallDesktop, setIsSmallDesktop] = useState(
    window.innerWidth > TABLET_END_WIDTH &&
      window.innerWidth < SMALL_DESKTOP_END_WIDTH
  );

  // Every width over XL
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > SMALL_DESKTOP_END_WIDTH
  );

  useEffect(() => {
    const width = size[0];

    setIsMobile(width < MOBILE_END_WIDTH);
    setIsTablet(width >= MOBILE_END_WIDTH && width < TABLET_END_WIDTH);
    setIsSmallDesktop(
      width >= TABLET_END_WIDTH && width < SMALL_DESKTOP_END_WIDTH
    );
    setIsDesktop(width >= SMALL_DESKTOP_END_WIDTH);
  }, [size]);

  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return { size, isTablet, isMobile, isSmallDesktop, isDesktop };
};

export default useWindowSize;
