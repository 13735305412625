/**
 * Returns object of { prev, current, next } gallery images
 * @param {Object} images
 * @param {number} imageId
 * @returns {Object}
 */
const getImageSetById = (images, imageId) => {
  let prev = null;
  let current = null;
  let next = null;

  for (let i = 0; i < images.length; i++) {
    const image = images[i];

    // Find and set image by ID
    if (image.id === parseInt(imageId)) {
      current = image;
    }

    // If this is not the first image in gallery set previous image
    if (i > 0) {
      prev = images[i - 1];
    }

    if (current !== null) {
      // If this is not the last image in gallery set next image
      if (i + 1 <= images.length - 1) {
        next = images[i + 1];
      }

      // Break loop once image by ID has been found
      break;
    }
  }

  return { prev, current, next };
};

export { getImageSetById };
