import { apiGallery } from "api";
import { WhiteContainer } from "components/container";
import GoBackLink from "components/go-back-link/GoBackLink";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import PageLoader from "components/page-loader/PageLoader";
import PageTitle from "components/page_title/PageTitle";
import { ConfigContext } from "context";
import T from "locales";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getImageSetById } from "./utils";
import routes from "pages/routes";
import RightArrowLink from "./components/arrow-links/RightArrowLink";
import LeftArrowLink from "./components/arrow-links/LeftArrowLink";
import ImageModal from "./components/image-modal/ImageModal";
import RichText from "components/richtext/RichText";

const GalleryDetailPage = () => {
  const { galleryId, imageId } = useParams();
  const { language } = useContext(ConfigContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [nextImage, setNextImage] = useState(null);
  const [prevImage, setPrevImage] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const { isLoading, error, data } = useQuery(
    ["gallery", language, galleryId],
    () => apiGallery(language, galleryId)
  );

  useEffect(() => {
    if (!data) return;

    // Set first image from gallery if specific image has not been selected
    if (!imageId && data.images.length > 0) {
      setSelectedImage(data.images[0]);

      // Set next image if there are more then 1 images in gallery
      if (data.images.length > 1) {
        setNextImage(data.images[1]);
      }
    } else {
      const { prev, current, next } = getImageSetById(data.images, imageId);

      setPrevImage(prev);
      setSelectedImage(current);
      setNextImage(next);
    }
  }, [imageId, data]);

  return (
    <>
      <PageTitle title={<T k="gallery_title" />} desc={data?.name} />

      <PageLoader isLoading={isLoading} error={error} />

      {data && (
        <WhiteContainer>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} className="py-4">
              <GoBackLink url={routes.gallery} />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <h2>{data.name}</h2>
            </Col>
          </Row>
          {data && data.images.length === 0 && (
            <Row>
              <Col className="py-5 text-center">
                <T k="gallery_is_empty" />
              </Col>
            </Row>
          )}
          {selectedImage && (
            <>
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <Row>
                    <Col className="text-center py-4">
                      <LazyLoadImg
                        image={selectedImage.thumbnails.large}
                        alt={
                          selectedImage.description ||
                          selectedImage.image.default_alt_text ||
                          selectedImage.image.name
                        }
                        onClick={() => setShowModal(true)}
                        className="cursor-pointer"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="text-left" md="2" xs="3">
                      <LeftArrowLink gallery={data} image={prevImage} />
                    </Col>
                    <Col className="text-center" md="8" xs="6">
                      {selectedImage.description}
                    </Col>
                    <Col className="text-right" md="2" xs="3">
                      <RightArrowLink gallery={data} image={nextImage} />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {data?.description && (
                <Row>
                  <Col lg={{ span: 10, offset: 1 }} className="py-5">
                    <RichText text={data.description} />
                  </Col>
                </Row>
              )}

              <ImageModal
                show={showModal}
                image={selectedImage}
                handleClose={() => setShowModal(false)}
              />
            </>
          )}
        </WhiteContainer>
      )}
    </>
  );
};

export default GalleryDetailPage;
