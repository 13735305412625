import { useHistory } from "react-router";
import { BiChevronLeft as ArrowLeftIcon } from "react-icons/bi";
import T from "locales";
import { Link } from "react-router-dom";

const DEFAULT_URL = "/";

const GoBackLink = ({ url = DEFAULT_URL }) => {
  const history = useHistory();

  const onGoBackClick = (e) => {
    // Skip going back in history if a custom URL is given
    if (url !== DEFAULT_URL) {
      return;
    }

    e.preventDefault();
    history.goBack();
  };

  return (
    <Link to={url} onClick={onGoBackClick} className="tfs-small">
      <ArrowLeftIcon /> <T k="go_back" />
    </Link>
  );
};

export default GoBackLink;
