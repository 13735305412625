import { Row } from "react-bootstrap";
import ButtonOrange from "components/button/ButtonOrange";
import ButtonOrangeBorder from "components/button/ButtonOrangeBorder";
import { T } from "locales/T";
import Items from "./Items";
import routes from "pages/routes";

const Events = () => {
  return (
    <>
      <Row className="mx-0 mb-5">
        <h2 className="my-auto">
          <T k="event_title" />
        </h2>
        <ButtonOrange
          to={routes.events}
          customClasses="ml-auto mr-5 d-none d-md-block"
        >
          <T k="see_all_button_title" />
        </ButtonOrange>
      </Row>
      <Row className="justify-content-start">
        <Items />
      </Row>
      <div className="d-flex justify-content-center">
        <ButtonOrangeBorder
          to={routes.events}
          customClasses="d-block d-md-none mb-5"
        >
          <T k="see_all_button_title" />
        </ButtonOrangeBorder>
      </div>
    </>
  );
};

export default Events;
