import T from "locales";
import { Row, Col } from "react-bootstrap";
import SignUpForm from "./Form";
import "./styles.scss";

const SignUp = () => {
  return (
    <Row className="justify-content-center section-py">
      <Col md="8" lg="6">
        <h2 className="text-center">
          <T k="sign_up_title" />
        </h2>
        <p className="text-center mb-5 mt-3">
          <T k="sign_up_text" />
        </p>
        <SignUpForm />
      </Col>
    </Row>
  );
};

export default SignUp;
