import T from "locales";

const List = ({ data, title }) => {
  return (
    <>
      <h6 className="tfs-large font-weight-bold tc-orange">{title}</h6>
      <ul className="pl-3">
        {data.map((item, index) => (
          <li key={index}>
            <T k={item.text} />
          </li>
        ))}
      </ul>
    </>
  );
};

export default List;
