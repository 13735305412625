import { Button, Spinner } from "react-bootstrap";
import "./styles.scss";

const Submit = ({ isLoading = false, children, classes, ...rest }) => {
  return (
    <Button
      variant="primary"
      type="submit"
      className={`custom-submit-button bg-transparent tfs-small rounded-0 ${classes}`}
      {...rest}
    >
      {isLoading && (
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      {!isLoading && children}
    </Button>
  );
};

export default Submit;
