import { apiSearch } from "api";
import { WhiteContainer } from "components/container";
import ErrorAlert from "components/error-alert/ErrorAlert";
import { Input, Submit } from "components/forms";
import Pagination from "components/pagination/Pagination";
import { ConfigContext } from "context";
import T from "locales";
import { getLocaleText } from "locales/utils";
import { useContext, useState } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import SearchItem from "./components/SearchItem";

const RESULTS_PER_PAGE = 20;

const SearchPage = () => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const { language, locales } = useContext(ConfigContext);
  const search = useMutation(apiSearch);

  /**
   * Handle search submit
   * @param {object} e
   */
  const onSubmitForm = (e) => {
    e.preventDefault();
    setSearchPhrase(e.target.search.value);
    search.mutate({ data: new FormData(e.target), language: language });
  };

  /**
   * Return boolean if pagination should be shown
   * @returns {boolean}
   */
  const shouldShowPagination = () => {
    const resultCount = search?.data?.length || 0;
    return resultCount > RESULTS_PER_PAGE;
  };

  /**
   * Return pagination search results
   * @returns {Array}
   */
  const getPaginatedResults = () => {
    const results = search?.data || [];
    const start = currentPage * RESULTS_PER_PAGE;
    const end = start + RESULTS_PER_PAGE;
    return results.slice(start, end);
  };

  /**
   * Handles page change
   * @param {number} selectedPage
   */
  const onPageChange = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    // Scroll to top of the page
    window.scrollTo(0, 0);
  };

  return (
    <>
      <WhiteContainer>
        <Row>
          <Col className="pt-5 pb-2">
            <h3>
              <T k="search_title" />
            </h3>
          </Col>
        </Row>
        <Form onSubmit={onSubmitForm}>
          <Row>
            <Col md="8" lg="9">
              <Input
                name="search"
                placeholder={getLocaleText(locales, "search_search_by")}
              />
            </Col>
            <Col md="4" lg="3">
              <Submit isLoading={search.isLoading} classes="w-100 mb-3 mb-md-2">
                <T k="search_search" />
              </Submit>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col className="pb-1 tc-gray-4 tfs-very-small mb-3">
            <T k="search_note" />
          </Col>
        </Row>

        {search.isError && (
          <Row>
            <Col>
              <ErrorAlert message={search.error.error} />
            </Col>
          </Row>
        )}

        {search.isSuccess && search?.data?.length > 0 && (
          <Row>
            <Col className="pb-5">
              {getPaginatedResults().map((item, index) => (
                <SearchItem
                  data={item}
                  key={index}
                  searchPhrase={searchPhrase}
                />
              ))}
            </Col>
          </Row>
        )}

        {search.isSuccess && search?.data?.length === 0 && (
          <Row>
            <Col>
              <Alert variant="warning">
                <T k="search_nothing_found" />
              </Alert>
            </Col>
          </Row>
        )}

        {shouldShowPagination() > 0 && (
          <Row>
            <Col className="mt-4 pb-5 mb-5">
              <Pagination
                pageCount={search.data.length / RESULTS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            </Col>
          </Row>
        )}
      </WhiteContainer>
    </>
  );
};

export default SearchPage;
