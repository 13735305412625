import ReactPaginate from "react-paginate";
import { BsChevronLeft as ArrowLeft } from "react-icons/bs";
import { BsChevronRight as ArrowRight } from "react-icons/bs";
import "./styles.scss";
import T from "locales";
import useWindowSize from "effects/useWindowSize";

const ITEMS_ON_SIDES = 1;
const ITEMS_AT_MIDDLE = 3;

const Pagination = ({ pageCount, currentPage, onPageChange }) => {
  const { isMobile } = useWindowSize();

  const ArrowWrapper = ({ children }) => {
    return <span>{children}</span>;
  };

  return (
    <ReactPaginate
      previousLabel={
        <ArrowWrapper>
          <ArrowLeft /> {!isMobile && <T k="pagination_previous" />}
        </ArrowWrapper>
      }
      nextLabel={
        <ArrowWrapper>
          {!isMobile && <T k="pagination_next" />} <ArrowRight />
        </ArrowWrapper>
      }
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={!isMobile ? ITEMS_ON_SIDES : 0}
      pageRangeDisplayed={ITEMS_AT_MIDDLE}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      containerClassName={
        "m-0 flat-list text-center tfs-small custom-pagination"
      }
      activeClassName={"active"}
      forcePage={currentPage}
    />
  );
};

export default Pagination;
