import PageTitle from "components/page_title/PageTitle";
import T from "locales";
import { useQuery } from "react-query";
import { ConfigContext } from "context";
import { useContext } from "react";
import { apiEvent } from "api";
import { useParams } from "react-router-dom";
import { GrayContainer } from "components/container";
import { Col, Row } from "react-bootstrap";
import { reverseDate } from "utils/dateHelpers";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import PageLoader from "components/page-loader/PageLoader";
import GoBackLink from "components/go-back-link/GoBackLink";
import RichText from "components/richtext/RichText";

const EventPage = () => {
  const { eventId } = useParams();
  const { language } = useContext(ConfigContext);

  const { isLoading, error, data } = useQuery(
    ["event", language, eventId],
    () => apiEvent(language, eventId)
  );

  return (
    <>
      <PageTitle title={<T k="event_title" />} />

      <PageLoader isLoading={isLoading} error={error} />

      {data && (
        <GrayContainer>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} className="py-4">
              <GoBackLink />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <Row>
                <Col>
                  <h2 className="text-center">{data.title}</h2>
                </Col>
              </Row>
              <Row>
                <Col className="text-center tc-orange font-weight-bold pb-4">
                  {reverseDate(data.date, true)}
                </Col>
              </Row>
              <Row>
                <Col className="text-center tc-orange font-weight-bold pb-4">
                  {data.type.name}
                </Col>
              </Row>
              <Row>
                <Col className="pb-5">
                  <LazyLoadImg
                    image={data.thumbnails.large}
                    atl={data.image.default_alt_text || data.image.name}
                  />
                </Col>
              </Row>
              <Row className="pb-5">
                <Col>
                  <RichText text={data.text} />
                </Col>
              </Row>
            </Col>
          </Row>
        </GrayContainer>
      )}
    </>
  );
};

export default EventPage;
