import T from "locales";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { getFromStorage, setOnStorage } from "utils/storage";
import useWindowSize from "effects/useWindowSize";

const STORAGE_KEY = "cookies_accepted";

const Cookies = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(
    getFromStorage(STORAGE_KEY)
  );
  const { isTablet, isMobile } = useWindowSize();

  const hideBanner = () => {
    setCookiesAccepted(true);
    setOnStorage(STORAGE_KEY, true);
  };

  if (cookiesAccepted) return null;

  return (
    <div
      style={{
        bottom: 0,
        zIndex: "2",
      }}
      className="position-fixed w-100 bc-blue-5 tc-white p-3"
    >
      <Row>
        <Col md="8" lg="10">
          <div
            style={
              !(isTablet || isMobile)
                ? {
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }
                : {}
            }
            className="w-100 text-left text-lg-center px-3"
          >
            <T k="cookies_text" />
          </div>
        </Col>
        <Col className="pt-3 pt-md-0">
          <Button onClick={hideBanner} className="w-100" variant="light">
            <T k="cookies_accept" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Cookies;
