import { ConfigContext } from "context";
import { useContext } from "react";
import { getLocaleText } from "./utils";

const T = ({ k }) => {
  const { locales } = useContext(ConfigContext);
  return getLocaleText(locales, k);
};

export { T };
