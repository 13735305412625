import "./styles.scss";

const DottedLoader = (props) => {
  return (
    <div className="snippet" {...props}>
      <div className="stage">
        <div className="dot-flashing"></div>
      </div>
    </div>
  );
};

export default DottedLoader;
