/**
 * Returns date string formatted to correct syntax
 * Examples:
 *  Simple format: 2021-04-01
 *  Advanced format: 2021-03-31T13:48:45.800573Z
 * @param {string} param
 * @returns {string}
 */
const reverseDate = (string, simple_format = false) => {
  let date = "";

  if (simple_format) {
    date = string.split("-").reverse().join(".");
  } else {
    date = string.split("T");
    date = date[0].split("-").reverse().join(".");
  }

  return date + ".";
};

/**
 * Returns an array of day, month (as a translation variable from locales) and a year
 * @param {array} data
 * @returns
 */
const seperateDate = (data) => {
  let months = [
    "month_jan",
    "month_feb",
    "month_march",
    "month_apr",
    "month_may",
    "month_jun",
    "month_jul",
    "month_aug",
    "month_sept",
    "month_oct",
    "month_nov",
    "month_dec",
  ];
  let date = data.split("-");

  date[1] = months[date[1] - 1];

  return date;
};

export { reverseDate, seperateDate };
