import { useState } from "react";
import PlaceholderImg from "./assets/placeholder.png";
import LargeImageModal from "./LargeImageModal";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";

const BigPersonCard = ({ image, largeImage, name, title, description }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <LazyLoadImg
        image={image || PlaceholderImg}
        alt={name}
        className={`w-100 ${largeImage && "cursor-pointer"}`}
        onClick={() => setShowModal(true)}
      />
      <div className="tc-orange tfs-large mt-3 w-100">{name}</div>
      <div className="tc-gray-4 w-100 tfs-small">{title}</div>
      <div className="tc-gray-4 w-100 tfs-very-small mt-3">{description}</div>

      {largeImage && (
        <LargeImageModal
          show={showModal}
          image={largeImage}
          name={name}
          title={title}
          description={description}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default BigPersonCard;
