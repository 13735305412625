import { WhiteContainer } from "components/container";
import { Col, Row, Alert } from "react-bootstrap";

const Error404Page = () => {
  return (
    <WhiteContainer>
      <Row>
        <Col>
          <Alert variant="dark" className="p-3 mt-4 text-center">
            Error 404
          </Alert>
        </Col>
      </Row>
    </WhiteContainer>
  );
};

export default Error404Page;
