import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyLoadImg = ({ image, alt, error, width = "100%", ...props }) => {
  return (
    <LazyLoadImage
      alt={alt}
      effect="blur"
      src={image}
      width={width}
      style={error ? { filter: "blur(8px)" } : null}
      {...props}
    />
  );
};

export default LazyLoadImg;
