import { Alert, Form } from "react-bootstrap";
import { useMutation } from "react-query";
import { ConfigContext } from "context";
import { useContext } from "react";
import { apiContact } from "api";
import T from "locales";
import { Input, Submit } from "components/forms";
import { getLocaleText } from "locales/utils";

const ContactForm = () => {
  const { language, locales } = useContext(ConfigContext);
  const entryMutation = useMutation(apiContact);

  const onSubmitForm = (e) => {
    e.preventDefault();
    entryMutation.mutate({ data: new FormData(e.target), language: language });
  };

  return (
    <>
      <Form onSubmit={onSubmitForm}>
        <Input
          type="text"
          placeholder={getLocaleText(locales, "contact_name_surname")}
          name="name"
          error={entryMutation.error}
          isSuccess={entryMutation.isSuccess}
        />
        <Input
          type="email"
          placeholder={getLocaleText(locales, "contact_email")}
          name="email"
          error={entryMutation.error}
          isSuccess={entryMutation.isSuccess}
        />
        <Input
          placeholder={getLocaleText(locales, "contact_text")}
          name="text"
          rows={3}
          as="textarea"
          error={entryMutation.error}
          isSuccess={entryMutation.isSuccess}
        />
        <Submit isLoading={entryMutation.isLoading}>
          <T k="contact_send_button" />
        </Submit>
      </Form>
      {entryMutation.isSuccess && (
        <Alert variant="success" className="mt-3">
          <T k="contact_message_success" />
        </Alert>
      )}
    </>
  );
};

export default ContactForm;
