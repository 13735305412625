/**
 * State reducer for publication api call data
 * @param {Object} state
 * @param {Object} action - {type: type, payload: payload}
 * @returns {Object}
 */
const publicationApiParamReducer = (state, action) => {
  switch (action.type) {
    case "set_type":
      return { ...state, type: action.payload };
    case "set_category":
      return { ...state, category_id: action.payload };
    case "set_all":
      return action.payload;
    default:
      return state;
  }
};

export default publicationApiParamReducer;
