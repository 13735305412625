import { Dropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { getArticleUrl } from "utils/sections";
import NavigationButton from "./NavigationButton";
import "./styles/NavigationSectionItem.scss";

const NavigationSectionItem = ({ section }) => {
  // Display dropdown button if the section contains subsections
  if (section.children.length > 0) {
    const dropdownItems = section.children.map((sub_section, index) => (
      <LinkContainer key={index} to={getArticleUrl(sub_section)}>
        <Dropdown.Item className="p-2 navigation-item-dropdown">
          {sub_section.name}
        </Dropdown.Item>
      </LinkContainer>
    ));

    return (
      <Dropdown>
        <Dropdown.Toggle className="tfs-large tc-gray-3 btn-nav">
          {section.name}
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight className="rounded-0 p-3 tfs-normal">
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <LinkContainer
      to={section.article ? getArticleUrl(section) : section.url}
      exact={section.url === "/"}
    >
      <NavigationButton className="tfs-large tc-gray-3">
        {section.name}
      </NavigationButton>
    </LinkContainer>
  );
};

export default NavigationSectionItem;
