/**
 * State reducer for config data
 * @param {Object} state
 * @param {Object} action - {type: type, payload: payload}
 * @returns {Object}
 */
const configReducer = (state, action) => {
  switch (action.type) {
    case "set_sections":
      return { ...state, sections: action.payload };
    case "change_language":
      // This is hardcoded because there won't be other languages
      return { ...state, language: state.language === "lv" ? "en" : "lv" };
    case "set_locales":
      return { ...state, locales: action.payload };
    case "change_font":
      return { ...state, font_size: action.payload };
    default:
      return state;
  }
};

export default configReducer;
