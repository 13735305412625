import { useState } from "react";
import PlaceholderImg from "./assets/placeholder.png";
import LargeImageModal from "./LargeImageModal";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";

const SmallPersonCard = ({ image, largeImage, name, title, note }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="w-100 d-flex">
        <div className="w-25">
          <LazyLoadImg
            image={image || PlaceholderImg}
            alt={name}
            className={`w-100 ${largeImage && "cursor-pointer"}`}
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="w-75 pt-1 pl-3">
          <div className="tc-orange tfs-large w-100">{name}</div>
          <div className="tc-gray-4 w-100 tfs-small">
            {title}
            {note && <div className="w-100">{note}</div>}
          </div>
        </div>
      </div>

      {largeImage && (
        <LargeImageModal
          show={showModal}
          image={largeImage}
          name={name}
          title={title}
          description={note}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default SmallPersonCard;
