import { useContext, useEffect, useState, useMemo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Modal from "components/modal/Modal";
import { apiPublication } from "api";
import { ConfigContext } from "context";
import { useQuery } from "react-query";
import {
  getIssuedAtText,
  getPublicationListURL,
} from "pages/publications/utils";
import ErrorAlert from "components/error-alert/ErrorAlert";
import DottedLoader from "components/dotted-loader/DottedLoader";
import RichText from "components/richtext/RichText";
import T from "locales";
import { getLocaleText } from "locales/utils";
import ButtonOrangeBorder from "components/button/ButtonOrangeBorder";
import { Row, Col } from "react-bootstrap";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import publicationPlaceholder from "assets/placeholders/publication-placeholder.png";
import { FaAngleLeft as BackIcon } from "react-icons/fa";
import useWindowSize from "effects/useWindowSize";

const PublicationModal = () => {
  const { language, locales } = useContext(ConfigContext);
  const { publicationId } = useParams();
  const routerHistory = useHistory();
  const location = useLocation();
  const [showPublicationModal, setShowPublicationModal] = useState(false);
  const [showLargeImage, setShowLargeImage] = useState(false);
  const { isMobile } = useWindowSize();

  const { isLoading, error, data } = useQuery(
    ["singlePublication", publicationId, language],
    () => {
      // Fetch publication only when ID is in URL
      if (!publicationId) return null;
      return apiPublication(language, publicationId);
    }
  );

  const issueAtText = useMemo(() => {
    if (!data) return "";

    return getIssuedAtText(
      data,
      getLocaleText(locales, "publication_page_count")
    );
  }, [data, locales]);

  const infoItems = useMemo(() => {
    if (!data) return [];

    // Return only non-empty items
    return [
      [data.compilers, <T k="publication_compilers" />],
      [data.author, <T k="publication_author" />],
      [data.translator, <T k="publication_translator" />],
      [issueAtText, <T k="publication_issue_at" />],
      [data.isbn, <T k="publication_isbn" />],
      [data.notes, <T k="publication_notes" />],
    ].filter((item) => item[0]);
  }, [data, issueAtText]);

  const PDFUrl = useMemo(() => {
    return data?.pdf_url || data?.pdf?.url || null;
  }, [data]);

  // Open modal when publication ID is in URL
  useEffect(() => {
    if (!publicationId) {
      setShowPublicationModal(false);
      setShowLargeImage(false);
      return;
    }

    setShowPublicationModal(true);
  }, [publicationId]);

  const closePublicationModal = () => {
    setShowPublicationModal(false);
    // Remove publication values from URL and go to that URL
    routerHistory.push(getPublicationListURL(location.pathname));
  };

  return (
    <>
      <Modal
        show={showPublicationModal}
        handleClose={closePublicationModal}
        title={data?.title}
        footer={data?.sub_title}
        size={showLargeImage ? "md" : "lg"}
      >
        {error && <ErrorAlert message={error.message} />}

        {isLoading && (
          <div className={error ? "pt-4 pb-3" : "py-3"}>
            <DottedLoader />
          </div>
        )}

        {data && (
          <div>
            {showLargeImage && (
              <Row>
                <Col className="pb-3">
                  <span
                    onClick={() => setShowLargeImage(false)}
                    className="tc-orange cursor-pointer th-underline"
                  >
                    <BackIcon /> <T k="publication_back_to_desc" />
                  </span>
                </Col>
              </Row>
            )}

            <Row>
              {data?.image?.url && (
                <Col md={showLargeImage ? "12" : "3"}>
                  <LazyLoadImg
                    image={data.image.url || publicationPlaceholder}
                    alt={
                      data.image.default_alt_text ||
                      data.image.name ||
                      data.title
                    }
                    onClick={() => !isMobile && setShowLargeImage(true)}
                    className={`${!isMobile && "cursor-pointer"} pb-3 pb-md-0`}
                  />
                </Col>
              )}

              {!showLargeImage && (
                <Col>
                  {infoItems.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={index !== infoItems.length - 1 ? "pb-2" : ""}
                      >
                        <strong>{item[1]}</strong> {item[0]}
                      </div>
                    );
                  })}
                </Col>
              )}
            </Row>

            {data.description && !showLargeImage && (
              <div>
                <hr />
                <RichText text={data.description} />
              </div>
            )}

            {PDFUrl && !showLargeImage && (
              <div className="pt-3 pb-2">
                <ButtonOrangeBorder to={PDFUrl} asPlain>
                  <T k="publication_download_pdf" />
                </ButtonOrangeBorder>
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default PublicationModal;
