import axios from "axios";
import config from "config";

/**
 * Return random short hash string
 * @returns {string}
 */
const getRandomUrlHash = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

/**
 * Return URL with language and random hash appended to it
 * @param {string} url
 * @param {string} language
 * @returns
 */
const addLanguageToUrl = (url, language) => {
  const prefix = url.includes("?") ? "&" : "?";

  return `${url}${prefix}language=${language}&sign=${getRandomUrlHash()}`;
};

/**
 * API GET request
 * Use with React Query quries: https://react-query.tanstack.com/guides/queries
 * @param {string} url
 * @param {string} language
 * @returns {(Object|Array|string)}
 */
const GET = (url, language) => {
  return axios
    .get(config.API_ENDPOINT + addLanguageToUrl(url, language), {
      headers: { "Accept-Language": language || config.DEFAULT_LANGUAGE },
    })
    .then(function (response) {
      return response.data;
    });
};

/**
 * API POST request
 * Use with React Query mutations: https://react-query.tanstack.com/guides/mutations
 * @param {string} url
 * @param {Object} data
 * @param {string} language
 * @returns {(Object|Array|string)}
 */
const POST = (url, data, language) => {
  return axios
    .post(config.API_ENDPOINT + url, data, {
      headers: {
        "Accept-Language": language || config.DEFAULT_LANGUAGE,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return new Promise(function (resolve, reject) {
        // Handle no response errors. Mainly the generic "Network error"
        if (!error.response) {
          reject({ error: "Network error", message: error });
        }

        // Handle invalid request response. Mainly for invalid form data
        if (error.response.status === 400) {
          reject(error.response.data);
        }

        // Handle all other unforeseen unhandled errors
        reject({ error: "Unhandled error", message: error });
      });
    });
};

export { GET, POST };
