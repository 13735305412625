import React, { useContext } from "react";
import { useQuery } from "react-query";
import { apiBanners } from "api";
import { ConfigContext } from "context";
import { Carousel, Col } from "react-bootstrap";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import loaderImg from "assets/placeholders/main-placeholder.png";
import "./styles.scss";
import DottedLoader from "components/dotted-loader/DottedLoader";
import Banner from "./components/banner/Banner";

const ImgCarousel = () => {
  const { language } = useContext(ConfigContext);

  // Fetch GET data from API
  const { isLoading, error, data } = useQuery(["bannerImages", language], () =>
    apiBanners(language)
  );

  // Display loading message for GET
  if (isLoading) {
    return (
      <Col style={{ height: "400px", background: "#fff" }}>
        <DottedLoader />
      </Col>
    );
  }

  // Display error message for GET
  if (error) {
    return (
      <Carousel indicators={false} controls={false}>
        <Carousel.Item>
          <LazyLoadImg image={loaderImg} alt="" error={error} />
          <Carousel.Caption className="tfs-normal">
            {"An error has occurred: " + error.message}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }

  if (data.length === 0) {
    return (
      <Carousel indicators={false} controls={false}>
        <Carousel.Item>
          <LazyLoadImg image={loaderImg} alt="" />
        </Carousel.Item>
      </Carousel>
    );
  }

  return (
    <Carousel indicators={false} controls={data.length > 1}>
      {data.map((item) => (
        <Carousel.Item key={item.id}>
          {item.url ? (
            <a href={item.url} className="th-no-underline">
              <Banner item={item} />
            </a>
          ) : (
            <Banner item={item} />
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImgCarousel;
