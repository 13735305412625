import config from "config";
import { mapObject } from "utils/common";
import { getFromStorage, setOnStorage } from "utils/storage";
import locales from "./texts";

const LANGUAGE_CACHE_KEY = "language";

/**
 * Returns current language key
 * @returns {string}
 */
const getLanguageKey = () => {
  const lang = getFromStorage(LANGUAGE_CACHE_KEY);

  if (lang === null) {
    return config.DEFAULT_LANGAUGE;
  }

  // Return storage language key only if it's valid
  for (let i = 0; i < config.LANGAUGES.length; i++) {
    if (lang === config.LANGAUGES[i].key) return lang;
  }

  // Set default value on storage if it contains invalid value
  setOnStorage(LANGUAGE_CACHE_KEY, config.DEFAULT_LANGAUGE);

  return config.DEFAULT_LANGAUGE;
};

/**
 * Returns current locales object {key: locale, ...}
 * @param {string} language
 * @returns {Object}
 */
const getLocales = (language) => {
  return mapObject(locales, (locale) => locale[language]);
};

/**
 * Return locale text by key from given locales array
 * @param {Array} localesTexts
 * @param {string} key
 * @returns {string}
 */
const getLocaleText = (localesTexts, key) => {
  const text = localesTexts ? localesTexts[key] : null;
  return text || "[MISSING LOCALE: " + key + "]";
};

export { LANGUAGE_CACHE_KEY, getLanguageKey, getLocales, getLocaleText };
