import { Card } from "react-bootstrap";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import { Link } from "react-router-dom";
import "./styles/PublicationCard.scss";
import { useParams } from "react-router-dom";
import publicationPlaceholder from "assets/placeholders/publication-placeholder.png";

const PublicationCard = ({ publication, isSmallMd }) => {
  const { pageId, categoryId, categorySlug } = useParams();

  const getCategoryUrlValues = () => {
    return {
      id: categoryId || publication.category.id,
      slug: categorySlug || publication.category.slug,
    };
  };

  const getUrl = () => {
    const category = getCategoryUrlValues();

    return [
      "/publications",
      "page",
      pageId || 1,
      "category",
      category.id,
      category.slug,
      "publication",
      publication.id,
      publication.slug,
    ].join("/");
  };

  return (
    <Link
      to={getUrl()}
      className="text-decoration-none"
      style={{ flex: "1 0" }}
      key={publication.id}
    >
      <Card
        className={
          "border-0 mx-auto mx-lg-1 mx-xl-2 bg-transparent publications-card " +
          (isSmallMd ? "small-md" : "")
        }
      >
        <div className="wrap mb-2">
          <LazyLoadImg
            image={publication.thumbnails?.small || publicationPlaceholder}
            alt={
              publication?.image?.default_alt_text ||
              publication?.image?.name ||
              publication.title
            }
          />
          <Card.Body className="px-0 pt-3">
            <Card.Title className="tfs-normal font-weight-bold mb-0">
              {publication.title}
            </Card.Title>
            <Card.Text className="tfs-small mt-2 tlh-1">
              {publication.sub_title}
            </Card.Text>
          </Card.Body>
        </div>
      </Card>
    </Link>
  );
};

export default PublicationCard;
