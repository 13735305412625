import T from "locales";
import { Row, Col } from "react-bootstrap";
import img from "./../../assets/main-activities.jpg";
import info from "./info";
import List from "./List";
import "./styles.scss";

const MainActivities = () => {
  return (
    <Row className="main-activities section-py">
      <Col
        xs={{ order: "last", span: "12" }}
        lg={{ order: "first" }}
        className="p-0 pt-lg-4 pr-lg-4"
      >
        <div className="square d-none d-lg-block"></div>
        <img src={img} alt="" className="mt-3 mt-lg-0"></img>
      </Col>
      <Col lg="7" className="pt-3 ml-0 ml-lg-5">
        <h2>
          <T k="main_activities_title" />
        </h2>
        <p className="tfs-normal mt-4 mb-5">
          <T k="main_activities_paragraph" />
        </p>
        <Row>
          <Col lg="7">
            <List
              data={info.ssrList}
              title={<T k="main_activities_ssr_title" />}
            />
          </Col>
          <Col>
            <List
              data={info.prList}
              title={<T k="main_activities_fr_title" />}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MainActivities;
