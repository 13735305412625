/**
 * Returns CSS class for global font size
 * @param {string} font_size
 * @returns {string}
 */
const getGlobalFontSizeCSS = (font_size) => {
  return "global-font-" + font_size;
};

/**
 * Return boolean if current url should be excluded from scroll to top on page change
 * @param {string} path
 * @returns {boolean}
 */
const shouldExclude = (excludableRoutes, path) => {
  return excludableRoutes.some(
    (route) => path.includes(route) && path.split("/").length > 2
  );
};

export { getGlobalFontSizeCSS, shouldExclude };
