import { FiCornerRightDown as ArrowIcon } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { getArticleUrl } from "utils/sections";
import "./styles/MobileNavigationItem.scss";

const MobileNavigationItem = ({ section, onMenuIsOpen }) => {
  const { children } = section;

  // For now, we assume there's going to be only one level of sub-sections
  if (children.length > 0) {
    return (
      <li className="pt-4 mobile-menu-list">
        {section.name} <ArrowIcon className="tfs-very-small" />
        <ul className="no-style-list ml-4">
          {children.map((sub_section) => (
            <li key={sub_section.id} className="pt-4">
              <NavLink
                to={
                  sub_section.article
                    ? getArticleUrl(sub_section)
                    : sub_section.url
                }
                onClick={onMenuIsOpen}
              >
                {sub_section.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  return (
    <li className="pt-4 mobile-menu-list">
      <NavLink
        to={section.article ? getArticleUrl(section) : section.url}
        exact={section.url === "/"}
        onClick={onMenuIsOpen}
      >
        {section.name}
      </NavLink>
    </li>
  );
};

export default MobileNavigationItem;
