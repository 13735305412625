import DottedLoader from "components/dotted-loader/DottedLoader";
import ErrorAlert from "components/error-alert/ErrorAlert";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import useWindowSize from "effects/useWindowSize";
import { Card, CardDeck } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.scss";
import coverPlaceholderImage from "assets/placeholders/main-placeholder.png";

const DESKTOP_ITEMS_A_ROW = 4;
const SMALL_DESKTOP_ITEMS_A_ROW = 3;
const TABLET_DESKTOP_ITEMS_A_ROW = 2;

const NewsCards = ({ isLoading, data, error, handleGetUrl, handleGetDate }) => {
  const { isTablet, isSmallDesktop, isDesktop } = useWindowSize();

  const getExtraCardCount = () => {
    const itemCount = data?.length || 0;

    if (itemCount === 0) {
      return 0;
    }

    // For xl there are 4 cards in a row
    if (isDesktop) {
      return DESKTOP_ITEMS_A_ROW - (itemCount % DESKTOP_ITEMS_A_ROW);
    }

    // For lg there are 3 cards in a row
    if (isSmallDesktop) {
      return (
        SMALL_DESKTOP_ITEMS_A_ROW - (itemCount % SMALL_DESKTOP_ITEMS_A_ROW)
      );
    }

    // For md there are 2 cards in a row
    if (isTablet) {
      return (
        TABLET_DESKTOP_ITEMS_A_ROW - (itemCount % TABLET_DESKTOP_ITEMS_A_ROW)
      );
    }

    return 0;
  };

  if (isLoading) {
    return (
      <Card className="border-0 bg-transparent">
        <DottedLoader />
      </Card>
    );
  }

  if (error) {
    return <ErrorAlert message={error.message} />;
  }

  if (data?.length === 0) {
    return null;
  }

  return (
    <CardDeck style={{ minHeight: "20rem" }}>
      {data.map((item) => (
        <Link
          to={handleGetUrl(item)}
          className="text-decoration-none"
          style={{ flex: "1 0" }}
          key={item.id}
        >
          <Card
            className="border-0 m-0 bg-transparent news-card"
            style={{ padding: "0.6rem", minWidth: "280px" }}
          >
            <div className="square"></div>
            <LazyLoadImg
              image={item?.thumbnails?.small || coverPlaceholderImage}
              alt={
                item?.image?.default_alt_text || item?.image?.name || item.title
              }
            />
            <Card.Body className="px-0 pt-3">
              {handleGetDate && (
                <Card.Text className="tc-orange tfs-small font-weight-bold mb-2">
                  {handleGetDate(item)}
                </Card.Text>
              )}
              <Card.Title className="tfs-normal font-weight-bold text-break mb-0">
                {item.title}
                {item.short_description && (
                  <div className="tfs-small pl-2 left-border-gray mt-2 font-weight-light">
                    {item.short_description}
                  </div>
                )}
              </Card.Title>
            </Card.Body>
          </Card>
        </Link>
      ))}
      {/* Render empty cards so all cards are left-aligned when there are not enough cards to fill the row */}
      {[...Array(getExtraCardCount()).keys()].map((i) => (
        <div style={{ flex: "1 0" }} key={i}>
          <Card
            className="border-0 m-0 bg-transparent news-card"
            style={{ padding: "0.6rem", minWidth: "280px" }}
          ></Card>
        </div>
      ))}
    </CardDeck>
  );
};

export default NewsCards;
