import { Link } from "react-router-dom";
import "./styles/ButtonOrangeBorder.scss";

const ButtonOrangeBorder = ({ to, children, customClasses, asPlain }) => {
  const Wrapper = ({ children, ...rest }) => {
    if (asPlain) {
      return (
        <a {...rest} href={to} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    }

    return <Link {...rest}>{children}</Link>;
  };

  return (
    <Wrapper
      to={to}
      className={
        "font-weight-bold tfs-very-small text-center text-uppercase button-orange-border " +
        customClasses
      }
    >
      {children}
    </Wrapper>
  );
};

export default ButtonOrangeBorder;
