import { Container } from "react-bootstrap";

const WhiteContainer = ({ children, ...rest }) => {
  return (
    <Container className="bc-white" {...rest}>
      {children}
    </Container>
  );
};

export default WhiteContainer;
