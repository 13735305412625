import { Button } from "react-bootstrap";
import "./styles/NavigationButton.scss";

const NavigationButton = (props) => {
  const { children, className, ...other } = props;

  return (
    <Button className={"btn-nav " + className} {...other}>
      {children}
    </Button>
  );
};

export default NavigationButton;
