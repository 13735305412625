import { ImArrowRight2 as RightArrowIcon } from "react-icons/im";
import ArrowLink from "./ArrowLink";

const RightArrowLink = ({ gallery, image }) => {
  return (
    <ArrowLink gallery={gallery} image={image}>
      <RightArrowIcon
        className={
          "tfs-very-very-large ml-1 " + (image ? "tc-orange" : "tc-gray-2")
        }
      />
    </ArrowLink>
  );
};

export default RightArrowLink;
