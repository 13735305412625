import { LinkContainer } from "react-router-bootstrap";
import { Breadcrumb } from "react-bootstrap";

const NAME_MAX_LENGTH = 30;

const Breadcrumbs = ({ article, subArticles }) => {
  const getMainArticleUrl = () => {
    return ["/article", article.id, article.slug].join("/");
  };

  const getSubArticleUrl = (subArticle) => {
    return [
      "/article",
      article.id,
      article.slug,
      subArticle.id,
      subArticle.slug,
    ].join("/");
  };

  const getName = (name) => {
    if (name.length > NAME_MAX_LENGTH) {
      return `${name.substr(0, NAME_MAX_LENGTH)}...`;
    }

    return name;
  };

  return (
    <Breadcrumb>
      <LinkContainer to={getMainArticleUrl()} exact>
        <Breadcrumb.Item>{article.name}</Breadcrumb.Item>
      </LinkContainer>
      {subArticles.map((subArticle) => (
        <LinkContainer
          to={getSubArticleUrl(subArticle)}
          key={subArticle.id}
          exact
        >
          <Breadcrumb.Item>{getName(subArticle.name)}</Breadcrumb.Item>
        </LinkContainer>
      ))}
    </Breadcrumb>
  );
};
export default Breadcrumbs;
