import { ConfigContext } from "context";
import { useContext } from "react";
import { getGlobalFontSizeCSS } from "./utils";

const Main = ({ children }) => {
  const { font_size } = useContext(ConfigContext);

  return (
    <main
      role="main"
      className={"flex-shrink-0 " + getGlobalFontSizeCSS(font_size)}
    >
      {children}
    </main>
  );
};

export default Main;
