import { WhiteContainer } from "components/container";
import T from "locales";
import { Alert, Col, Row } from "react-bootstrap";
import config from "config";

const AdminPage = () => {
  window.location.href = config.ADMIN_URL;

  return (
    <WhiteContainer>
      <Row>
        <Col>
          <Alert variant="dark" className="p-3 mt-4 text-center">
            <T k="admin_redirect" />
          </Alert>
        </Col>
      </Row>
    </WhiteContainer>
  );
};

export default AdminPage;
