import config from "config";

/**
 * Returns other than current language
 * @param {string} language
 * @returns {string}
 */
const getOtherLanguage = (language) => {
  // This is hardcoded because there won't be other languages
  return {
    lv: config.LANGAUGES[1].value,
    en: config.LANGAUGES[0].value,
  }[language];
};

export { getOtherLanguage };
