import DottedLoader from "components/dotted-loader/DottedLoader";
import { Col, Row } from "react-bootstrap";
import WhiteContainer from "components/container/WhiteContainer";
import ErrorAlert from "components/error-alert/ErrorAlert";

const PageLoader = ({ isLoading, error }) => {
  if (!(isLoading || error)) {
    return null;
  }

  return (
    <WhiteContainer>
      <Row className="py-5">
        <Col>
          {error && <ErrorAlert message={error.message} />}
          {isLoading && <DottedLoader />}
        </Col>
      </Row>
    </WhiteContainer>
  );
};

export default PageLoader;
