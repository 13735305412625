import { useQuery } from "react-query";
import { apiPublicationCategories } from "api";
import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "context";
import DottedLoader from "components/dotted-loader/DottedLoader";
import { useParams } from "react-router-dom";
import {
  CategoryRow,
  CategoryHeadingRow,
  NavigationWrapper,
} from "components/sub-sections";
import T from "locales";
import ErrorAlert from "components/error-alert/ErrorAlert";
import { getPublicationPageURL } from "pages/publications/utils";

const Categories = ({ handleSelect, handleSelectSection }) => {
  const { categoryId, sectionId } = useParams();
  const { language } = useContext(ConfigContext);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { isLoading, error, data } = useQuery(
    ["publicationCategories", language],
    () => apiPublicationCategories(language)
  );

  /**
   * Set category based on URL value
   */
  useEffect(() => {
    const isSelected = (c) => categoryId === c.id.toString();

    if (data) {
      const publicataionCategory = data.publication.find((c) => isSelected(c));
      const magazineCategory = data.magazine.find((c) => isSelected(c));
      const category = publicataionCategory || magazineCategory;

      handleSelect(category);
      setSelectedCategory(category);
    }
  }, [handleSelect, categoryId, data]);

  /**
   * Set section based on URL value
   */
  useEffect(() => {
    if (sectionId && selectedCategory) {
      handleSelectSection(
        selectedCategory.sections.find((s) => sectionId === s.id.toString())
      );
    } else {
      handleSelectSection(null);
    }
  }, [handleSelectSection, selectedCategory, sectionId]);

  /**
   * Returns full category URL
   * @param {Object} category
   * @returns {string}
   */
  const getUrl = (category) => {
    return getPublicationPageURL(1, category.id, category.slug);
  };

  /**
   * Return true if given category is selected
   * @param {Object} category
   * @returns {boolean}
   */
  const isSelected = (category) => {
    return categoryId === category.id.toString();
  };

  /**
   * Returns true if categories should be shown
   * @returns {boolean}
   */
  const showCategories = () => {
    return selectedCategory?.sections?.length > 0;
  };

  /**
   * Returns full category section URL
   * @param {Object} section
   * @returns {string}
   */
  const getSectionUrl = (section) => {
    return [
      "/publications",
      "category",
      selectedCategory.id,
      selectedCategory.slug,
      section.id,
      section.slug,
    ].join("/");
  };

  /**
   * Return true if given section is selected
   * @param {Object} section
   * @returns {boolean}
   */
  const isSectionSelected = (section) => {
    return sectionId === section.id.toString();
  };

  if (isLoading || error) {
    return (
      <NavigationWrapper>
        {isLoading && (
          <div className={error && "mb-3"}>
            <DottedLoader />
          </div>
        )}
        {error && <ErrorAlert message={error.message} />}
      </NavigationWrapper>
    );
  }

  return (
    <>
      {showCategories() && (
        <NavigationWrapper>
          <CategoryHeadingRow text={selectedCategory.name} />

          {selectedCategory.sections.map((section) => (
            <CategoryRow
              key={section.id}
              handleSelect={handleSelectSection}
              category={section}
              handleGetUrl={getSectionUrl}
              handleIsSelected={isSectionSelected}
            />
          ))}
        </NavigationWrapper>
      )}

      <NavigationWrapper moveUp={!showCategories()} bottomSpacing>
        <CategoryHeadingRow
          text={<T k="publication_category_publications" />}
        />

        {data.publication.map((category) => (
          <CategoryRow
            key={category.id}
            handleSelect={handleSelect}
            category={category}
            handleGetUrl={getUrl}
            handleIsSelected={isSelected}
          />
        ))}

        <CategoryHeadingRow
          text={<T k="publication_category_magazines" />}
          extraTopPadding
        />

        {data.magazine.map((category, i) => (
          <CategoryRow
            key={category.id}
            handleSelect={handleSelect}
            category={category}
            isLast={i === data.magazine.length - 1}
            handleGetUrl={getUrl}
            handleIsSelected={isSelected}
          />
        ))}
      </NavigationWrapper>
    </>
  );
};

export default Categories;
