import { Row } from "react-bootstrap";
import ButtonOrange from "components/button/ButtonOrange";
import ButtonOrangeBorder from "components/button/ButtonOrangeBorder";
import { T } from "locales/T";
import routes from "pages/routes";
import NewsCards from "components/news-cards/NewsCards";
import { useContext } from "react";
import { ConfigContext } from "context";
import { useQuery } from "react-query";
import { apiLatestNews } from "api";
import { reverseDate } from "utils/dateHelpers";

const LATEST_NEWS_COUNT = 4;

const News = () => {
  const { language } = useContext(ConfigContext);

  const { isLoading, error, data } = useQuery(
    ["latestNews", language, LATEST_NEWS_COUNT],
    () => apiLatestNews(language, LATEST_NEWS_COUNT)
  );

  const getUrl = (item) => {
    // If slug is not returned placeholder value has to be passed
    return ["news", item.id, item.slug || "none"].join("/");
  };

  return (
    <>
      <Row className="mx-0 my-5">
        <h2 className="my-auto">
          <T k="news_title" />
        </h2>
        <ButtonOrange
          to={routes.news}
          customClasses="ml-auto mr-5 d-none d-md-block"
        >
          <T k="see_all_button_title" />
        </ButtonOrange>
      </Row>

      <NewsCards
        isLoading={isLoading}
        data={data}
        error={error}
        handleGetUrl={getUrl}
        handleGetDate={(item) => reverseDate(item.date)}
      />

      <ButtonOrangeBorder
        to={routes.news}
        customClasses="d-block d-md-none mb-5"
      >
        <T k="see_all_button_title" />
      </ButtonOrangeBorder>
    </>
  );
};

export default News;
