import { WhiteContainer } from "components/container";
import PageTitle from "components/page_title/PageTitle";
import T from "locales";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Categories from "./components/categories/Categories";
import NewestPublications from "./components/publications/NewestPublications";
import NewestMagazines from "./components/publications/NewestMagazines";
import CategoryPublications from "./components/publications/CategoryPublications";
import PublicationModal from "./components/publications/PublicationModal";
import CategorySection from "./components/categories/CategorySection";

const PublicationsPage = () => {
  const { categoryId } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  return (
    <>
      <PageTitle
        title={<T k="publication_title" />}
        desc={<T k="publication_page_desc" />}
        largeDescriptionPadding
      />
      <WhiteContainer>
        <Row>
          <Col md="12" lg="3" className="">
            <Categories
              handleSelect={setSelectedCategory}
              handleSelectSection={setSelectedSection}
            />
          </Col>
          <Col md="12" lg="9" xl={{ span: "8", offset: 1 }} className="pb-5">
            <Row>
              <Col>
                {!categoryId && (
                  <>
                    <NewestPublications />
                    <NewestMagazines />
                  </>
                )}
                {selectedSection && (
                  <CategorySection
                    category={selectedCategory}
                    section={selectedSection}
                  />
                )}
                {categoryId && !selectedSection && (
                  <CategoryPublications category={selectedCategory} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </WhiteContainer>
      <PublicationModal />
    </>
  );
};

export default PublicationsPage;
