import useWindowSize from "effects/useWindowSize";
import { Card, CardDeck } from "react-bootstrap";
import PublicationCard from "./PublicationCard";
import "./styles/PublicationCardDeck.scss";

const DESKTOP_ITEMS_A_ROW = 4;
const MOBILE_ITEMS_A_ROW = 4;

const PublicationCardDeck = ({
  publications,
  className,
  isSmallMd,
  insertEmptyCards = false,
  ...props
}) => {
  const { isMobile } = useWindowSize();

  const getExtraCardCount = () => {
    const itemCount = publications?.length || 0;

    if (itemCount === 0) {
      return 0;
    }

    // For mobile
    if (isMobile) {
      return MOBILE_ITEMS_A_ROW - (itemCount % MOBILE_ITEMS_A_ROW);
    }

    // For all other sizes
    return DESKTOP_ITEMS_A_ROW - (itemCount % DESKTOP_ITEMS_A_ROW);
  };

  return (
    <CardDeck className={`publication-card-deck ${className}`} {...props}>
      {publications.map((publication) => (
        <PublicationCard
          key={publication.id}
          isSmallMd={isSmallMd}
          publication={publication}
        />
      ))}
      {/* Render empty cards so all cards are left-aligned when there are not enough cards to fill the row */}
      {insertEmptyCards &&
        [...Array(getExtraCardCount()).keys()].map((i) => (
          <div style={{ flex: "1 0" }} key={i}>
            <Card
              className={
                "border-0 mx-auto mx-lg-1 mx-xl-2 bg-transparent publications-card " +
                (isSmallMd ? "small-md" : "")
              }
            ></Card>
          </div>
        ))}
    </CardDeck>
  );
};

export default PublicationCardDeck;
