import React from "react";
import "./styles.scss";
import routes from "pages/routes";
import { useHistory } from "react-router-dom";
import replace from "lodash/replace";

/**
 * Contains list of local urls first elements: ['gallery', 'article', ...]
 */
const LOCAL_URLS = Object.entries(routes).map((r) =>
  r[1].split("/")?.[1].length > 0 ? r[1].split("/")?.[1] : null
);

function RichText({ text }) {
  const routerHistory = useHistory();

  const onLinkClick = (e) => {
    const targetIsLink = e.target.closest("a");

    if (targetIsLink) {
      const url = targetIsLink.getAttribute("href");
      const urlFirstName = url.split("/")?.[1];

      // Use router if it's local url
      if (LOCAL_URLS.includes(urlFirstName)) {
        routerHistory.push(url);
        e.preventDefault();
      }
    }
  };

  const getText = () => {
    if (!text) return "";

    return replace(
      text,
      /https:\/\/lu-fsi-api.yattasoftware.com/g,
      "https://fsilu.vip.lv"
    );
  };

  return (
    <div
      className="wysiwyg"
      dangerouslySetInnerHTML={{ __html: getText() }}
      onClick={(e) => onLinkClick(e)}
    ></div>
  );
}

export default RichText;
