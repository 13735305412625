import { getFromStorage, setOnStorage, removeFromStorage } from "./storage";
import config from "config";

const CACHE_KEY = "sections";

/**
 * Return JSON string parsed from Object
 * @param {Object} obj
 * @returns {string}
 */
const parseObjectToJSONString = (obj) => {
  return JSON.stringify(obj);
};

/**
 * Return Object parsed from JSON string
 * @param {string} str
 * @returns {Object}
 */
const parseJSONStringToObject = (str) => {
  return JSON.parse(str);
};

/**
 * Returns sections storage data key
 * @param {string} language
 * @returns {string}
 */
const getCacheKey = (language) => {
  return CACHE_KEY + "_" + language;
};

/**
 * Returns sections data from storage or invalidates expired data
 * @param {string} language
 * @returns {(Array|null)}
 */
const getSectionItems = (language) => {
  const timestamp = +new Date();
  const key = getCacheKey(language);
  let storedItem = getFromStorage(key);

  if (storedItem) {
    storedItem = parseJSONStringToObject(storedItem);

    if (storedItem.expiration > timestamp) {
      return storedItem.data;
    }

    removeFromStorage(key);
  }

  return null;
};

/**
 * Stores sections data on storage
 * @param {Array} data
 * @param {string} language
 */
const storeSectionItems = (data, language) => {
  const timestamp = +new Date();
  const key = getCacheKey(language);
  const items = {
    data: data,
    expiration: timestamp + config.STORAGE_CACHE_EXPIRATION,
  };
  setOnStorage(key, parseObjectToJSONString(items));
};

export { getSectionItems, storeSectionItems };

/**
 * Returns React Router URL for article page
 * @param {Object} section
 * @returns {string}
 */
const getArticleUrl = (section) => {
  const { article, url } = section;

  if (article) {
    return ["/article", article.id, article.slug].join("/");
  }

  return url;
};

export { getArticleUrl };
