import { Col, Row } from "react-bootstrap";

const CategoryHeadingRow = ({
  text,
  extraTopPadding,
  showUnderline = true,
}) => {
  return (
    <Row>
      <Col
        className={
          "font-weight-bold tc-gray-4 text-uppercase tfs-very-small " +
          (extraTopPadding ? "pt-3" : "")
        }
      >
        {text}
        {showUnderline && <hr className="mt-3 mb-0 pb-3" />}
      </Col>
    </Row>
  );
};

export default CategoryHeadingRow;
