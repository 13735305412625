import { useState } from "react";
import T from "locales";
import { Form, Button, Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import { apiNewsletter } from "api";
import { useContext } from "react";
import { ConfigContext } from "context";

const SignUpForm = () => {
  const [active, setIsActive] = useState(false);
  const [value, setValue] = useState("");

  const { language } = useContext(ConfigContext);
  const entryMutation = useMutation(apiNewsletter);

  const handleTextChange = (text) => {
    setValue(text);
    setIsActive(text !== "");
  };

  const postEntry = (e) => {
    e.preventDefault();
    entryMutation.mutate({ data: new FormData(e.target), language: language });
  };

  return (
    <Form onSubmit={postEntry}>
      <Form.Group
        hidden={!entryMutation.isSuccess}
        className="text-center tc-orange font-weight-bold tfs-large fade-in"
      >
        <T k="newsletter_submit_success" />
      </Form.Group>
      {/* Show success */}
      <Form.Group
        className={
          entryMutation.isSuccess
            ? "d-md-flex py-2 px-3"
            : "d-md-flex border border-dark rounded py-2 px-3"
        }
      >
        <Form.Control
          isInvalid={entryMutation.isError}
          isValid={entryMutation.isSuccess}
          value={value}
          onChange={(e) => handleTextChange(e.target.value)}
          type="email"
          name="email"
          className={
            "border-0 shadow-none " +
            (entryMutation.isSuccess ? "text-center" : "")
          }
          style={
            entryMutation.isSuccess ? { padding: "30px 10px 30px 20px" } : {}
          }
          disabled={entryMutation.isSuccess}
        />

        {!entryMutation.isError && (
          <label
            className={
              entryMutation.isSuccess
                ? "d-none"
                : active
                ? "tfs-small active"
                : "tfs-small"
            }
            htmlFor="email"
          >
            <T k="email_address_text" />
          </label>
        )}
        {/* Show error */}
        {entryMutation.isError && (
          <label className="active text-danger">
            {entryMutation.error.email}
          </label>
        )}
        {/* Show loading */}
        <div className="d-flex justify-content-center">
          {entryMutation.isLoading && (
            <div className="my-auto">
              <Spinner
                animation="border"
                size="sm"
                className="mr-1 tc-orange"
              />
            </div>
          )}

          <div
            className={
              entryMutation.isSuccess
                ? "d-none"
                : "submit-button text-center my-auto"
            }
          >
            <Button
              type="submit"
              className="border-0 bg-transparent tc-orange font-weight-bold text-uppercase tfs-small p-0 shadow-none"
            >
              <T k="sign_up_button" />
            </Button>
          </div>
        </div>
      </Form.Group>
    </Form>
  );
};

export default SignUpForm;
