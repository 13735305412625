import {
  GrayContainer,
  WhiteContainer,
  BlueGrayContainer,
} from "components/container";
import PageTitle from "components/page_title/PageTitle";
import T from "locales";
import { Col, Row } from "react-bootstrap";
import ImgCarousel from "./components/img-carousel/ImgCarousel";
import Events from "./components/events/Events";
import MainActivities from "./components/main-activities/MainActivities";
import News from "./components/news/News";
import Publications from "./components/publications/Publications";
import SignUp from "./components/newsletter/Newsletter";

const HomePage = () => {
  return (
    <>
      <PageTitle title={<T k="home_title" />} center={false} />
      <BlueGrayContainer>
        <Row>
          <Col>
            <ImgCarousel />
          </Col>
        </Row>
      </BlueGrayContainer>
      <GrayContainer>
        <Row>
          <Col className="mt-5">
            <News />
          </Col>
        </Row>
      </GrayContainer>
      <GrayContainer>
        <Row>
          <Col>
            <Publications />
          </Col>
        </Row>
      </GrayContainer>
      <WhiteContainer>
        <Row>
          <Col>
            <MainActivities />
          </Col>
        </Row>
      </WhiteContainer>
      <GrayContainer>
        <Row>
          <Col className="section-py">
            <Events />
          </Col>
        </Row>
      </GrayContainer>
      <WhiteContainer>
        <Row>
          <Col>
            <SignUp />
          </Col>
        </Row>
      </WhiteContainer>
    </>
  );
};

export default HomePage;
