import { AiOutlineSearch as SearchIcon } from "react-icons/ai";
import config from "config";
import { useContext } from "react";
import { ConfigContext } from "context";
import NavigationButton from "./NavigationButton";
import T from "locales";
import { getOtherLanguage } from "./utils";
import FontSizeDropdown from "./FontSizeDropdown";
import { useHistory } from "react-router-dom";
import routes from "pages/routes";

const HeaderInfo = () => {
  const { language, configDispatch } = useContext(ConfigContext);
  const routerHistory = useHistory();

  const changeLanguage = () => {
    routerHistory.push(routes.home);
    configDispatch({ type: "change_language" });
  };

  const handleSearchOpen = () => {
    routerHistory.push(routes.search);
  };

  return (
    <ul className="flat-list tc-gray-3 text-right">
      <li className="tfs-small">
        <T k="phone_short" />{" "}
        <a href={"tel: " + config.CONTACT.phone}>{config.CONTACT.phone}</a>
      </li>
      <li className="ml-4 tfs-small">
        <T k="email" />{" "}
        <a href={"mailto: " + config.CONTACT.email}>{config.CONTACT.email}</a>
      </li>
      <li className="ml-3">
        <NavigationButton
          className="tc-gray-3 tfs-small"
          onClick={handleSearchOpen}
        >
          <SearchIcon />
        </NavigationButton>
      </li>
      <li className="ml-2 ">
        <FontSizeDropdown sizeClass="tfs-small" />
      </li>
      <li className="ml-2">
        <NavigationButton
          className="tc-gray-3 tfs-small"
          onClick={changeLanguage}
        >
          {getOtherLanguage(language)}
        </NavigationButton>
      </li>
    </ul>
  );
};

export default HeaderInfo;
