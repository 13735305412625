import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./styles.scss";

const Input = ({ name, error, isSuccess, ...props }) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [value, setValue] = useState("");

  // Set error messages
  useEffect(() => {
    if (!error) return;
    setErrorMessages(error[name] || []);
  }, [error, name]);

  // Reset value on form submit success
  useEffect(() => {
    setValue("");
  }, [isSuccess]);

  return (
    <Form.Group controlId={name} className="custom-form-input-group">
      <Form.Control
        name={name}
        isInvalid={errorMessages.length > 0 && !isSuccess}
        className="rounded-0"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      />

      {errorMessages.map((message) => (
        <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
      ))}
    </Form.Group>
  );
};

export default Input;
