import { Container } from "react-bootstrap";

const BlueContainer = ({ children, className = "", ...rest }) => {
  return (
    <Container fluid className={"bc-blue-4 p-0 " + className} {...rest}>
      <Container>{children}</Container>
    </Container>
  );
};

export default BlueContainer;
