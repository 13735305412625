import { Row } from "react-bootstrap";
import ButtonOrange from "components/button/ButtonOrange";
import ButtonOrangeBorder from "components/button/ButtonOrangeBorder";
import { T } from "locales/T";
import Items from "./Items";

const ALL_PUBLICATION_URL = "/publications";

const Publications = () => {
  return (
    <>
      <Row className="mx-0 my-5">
        <h2 className="my-auto">
          <T k="latest_publications" />
        </h2>
        <ButtonOrange
          to={ALL_PUBLICATION_URL}
          customClasses="ml-auto mr-5 d-none d-md-block"
        >
          <T k="see_all_button_title_2" />
        </ButtonOrange>
      </Row>
      <Items />
      <ButtonOrangeBorder
        to={ALL_PUBLICATION_URL}
        customClasses="d-block d-md-none mb-5"
      >
        <T k="see_all_button_title_2" />
      </ButtonOrangeBorder>
    </>
  );
};

export default Publications;
