import Modal from "components/modal/Modal";

const LargeImageModal = ({
  show,
  image,
  name,
  title,
  description,
  onClose,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Modal
      show={show}
      title={`${name} (${title})`}
      footer={description}
      handleClose={onClose}
    >
      <img src={image} alt={name} className="w-100" />
    </Modal>
  );
};

export default LargeImageModal;
