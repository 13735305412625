import { BlueContainer } from "components/container";
import { Col, Row } from "react-bootstrap";

const PageTitle = ({ title, desc, center = true, largeDescriptionPadding }) => {
  return (
    <BlueContainer>
      <Row>
        <Col>
          <h1
            className={
              "tc-white pt-4 pb-4 m-0 " + (center ? "text-center" : "")
            }
          >
            {title}
          </h1>
        </Col>
      </Row>
      {desc && (
        <Row>
          <Col>
            <p
              className={
                "tc-white pt-0 m-0 font-weight-light " +
                (center ? "text-center " : "") +
                (largeDescriptionPadding ? "pb-5 " : "pb-4 ")
              }
            >
              {desc}
            </p>
          </Col>
        </Row>
      )}
    </BlueContainer>
  );
};

export default PageTitle;
