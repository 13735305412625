import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import { Row, Col } from "react-bootstrap";
import { FaFileAlt as FileIcon } from "react-icons/fa";
import T from "locales";
import "./styles.scss";
import Modal from "components/modal/Modal";
import { useState } from "react";

const Section = ({ ltr, pdf, textRange, img, imgTitleNr, hasLargeImage }) => {
  const [showLargeImageModal, setShowLargeImageModal] = useState(false);

  let imageTitle = "about_us_img_title_" + imgTitleNr;

  const getTexts = () => {
    const texts = [];

    for (let i = textRange[0]; i <= textRange[1]; i++) {
      texts.push(<T k={"about_us_text_" + i} />);
    }

    return texts;
  };

  if (ltr) {
    return (
      <Row className="">
        <Col md="5" lg="4" className="img-wrap text-center mb-5 mb-md-0">
          <div className="position-relative">
            <div className="rectangle-ltr p-2 d-flex align-items-center tfs-very-small text-white">
              <T k={imageTitle} />
            </div>
            <LazyLoadImg
              image={img}
              alt=""
              onClick={() => setShowLargeImageModal(true)}
              className={`${hasLargeImage && "cursor-pointer"}`}
            />
            {hasLargeImage && (
              <Modal
                show={showLargeImageModal}
                handleClose={() => setShowLargeImageModal(false)}
                size="xl"
                title={<T k={imageTitle} />}
              >
                <LazyLoadImg image={img} alt="" />
              </Modal>
            )}
          </div>
        </Col>
        <Col md="7" lg="8" className="my-auto">
          {getTexts().map((i, index) => (
            <p key={index}>{i}</p>
          ))}
          {pdf ? (
            <a href="/" target="_blank" className="pdf-link">
              <FileIcon className="tc-orange tfs-small" /> Nolikums_2020.pdf
            </a>
          ) : null}
        </Col>
      </Row>
    );
  }

  return (
    <Row className="my-5">
      <Col md="7" lg="8" className="my-auto">
        {getTexts().map((i, index) => (
          <p key={index}>{i}</p>
        ))}
      </Col>
      <Col md="5" lg="4" className="img-wrap mt-5 mt-md-0">
        <div className="position-relative">
          <div className="rectangle-rtl p-2 d-flex align-items-center tfs-very-small text-white">
            <T k={imageTitle} />
          </div>
          <LazyLoadImg image={img} alt="" />
        </div>
      </Col>
    </Row>
  );
};

export default Section;
