import RichText from "components/richtext/RichText";
import T from "locales";
import { Col, Row, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

// Search result API types
const TYPES = {
  NEWS: "news",
  ARTICLE: "article",
  PUBLICATION: "publication",
  EVENT: "event",
};

// Search result type literal values based on API types
const TYPE_LITERALS = {
  [TYPES.NEWS]: <T k="search_news" />,
  [TYPES.ARTICLE]: <T k="search_article" />,
  [TYPES.PUBLICATION]: <T k="search_publication" />,
  [TYPES.EVENT]: <T k="search_event" />,
};

const MAX_TEXT_LENGTH = 350;

const SearchItem = ({ data, searchPhrase }) => {
  const { id, category_id, category_slug, name, slug, type, text, tags } = data;

  /**
   * Return full url for the search result
   * @returns {string}
   */
  const getUrl = () => {
    let url = "/";

    if (type === TYPES.NEWS) {
      url = `/news/${id}/${slug}`;
    } else if (type === TYPES.EVENT) {
      url = `/events/${id}/${slug}`;
    } else if (type === TYPES.PUBLICATION) {
      // Page "1" so the publication is opened in modal but in reality, it might not be on page "1"
      url = `/publications/page/1/category/${category_id}/${category_slug}/publication/${id}/${slug}`;
    } else if (type === TYPES.ARTICLE) {
      url = `/article/${id}/${slug}`;
    }

    return url;
  };

  /**
   * Return search result title with search phrase colored
   * @returns {string}
   */
  const colorSearchPhrase = () => {
    const words = name.split(" ");

    const updated = words.map((word) => {
      const phraseStart = word.toLowerCase().search(searchPhrase.toLowerCase());

      if (phraseStart > -1) {
        const firstPart = phraseStart > 0 ? word.slice(0, phraseStart) : "";
        const middlePart = `<span class="bc-orange-light" style="padding: 1px">${word.slice(
          phraseStart,
          phraseStart + searchPhrase.length
        )}</span>`;
        const secondPart = word.slice(
          phraseStart + searchPhrase.length,
          word.length
        );
        return `${firstPart}${middlePart}${secondPart}`;
      }

      return word;
    });

    return updated.join(" ");
  };

  /**
   * Returns search result text substring
   * @returns {string}
   */
  const getText = () => {
    return `${text.slice(0, MAX_TEXT_LENGTH)}${
      text.length > MAX_TEXT_LENGTH && "[...]"
    }`;
  };

  return (
    <Row>
      <Col className="pb-4">
        <hr className="mt-0" />
        <Link to={getUrl()}>
          <div className="tc-orange tfs-small w-100">{TYPE_LITERALS[type]}</div>
          <div
            className="font-weight-bold tfs-large"
            dangerouslySetInnerHTML={{ __html: colorSearchPhrase() }}
          ></div>
          {text && (
            <div className="tc-gray-4 tfs-small text-break">
              <RichText text={getText()} />
            </div>
          )}
          {tags?.map((tag) => (
            <Badge variant="light" className="mr-2">
              {tag}
            </Badge>
          ))}
        </Link>
      </Col>
    </Row>
  );
};

export default SearchItem;
