import { useQuery } from "react-query";
import { apiAllPublications } from "api";
import Publications from "./Publications";
import { useContext, useEffect, useReducer } from "react";
import { ConfigContext } from "context";
import { useParams, useHistory } from "react-router-dom";
import publicationApiParamReducer from "reducers/publicationApiParamReducer";
import {
  getCategory,
  getType,
  getPageCount,
  getPublicationPageURL,
} from "pages/publications/utils";
import { Row, Col } from "react-bootstrap";
import Pagination from "components/pagination/Pagination";
import config from "config";

const CategoryPublications = ({ category }) => {
  const { pageId, categoryId, categorySlug } = useParams();
  const { language } = useContext(ConfigContext);
  const routerHistory = useHistory();

  const [apiParams, apiParamDispatch] = useReducer(publicationApiParamReducer, {
    type: getType(categoryId),
    category_id: getCategory(categoryId),
  });

  const { isLoading, error, data } = useQuery(
    [
      "categoryPublications",
      language,
      apiParams.type,
      apiParams.category_id,
      pageId || "all",
    ],
    () =>
      apiAllPublications({
        language,
        type: apiParams.type,
        category: apiParams.category_id,
        page: pageId,
      })
  );

  useEffect(() => {
    if (!categoryId) {
      return;
    }

    apiParamDispatch({
      type: "set_all",
      payload: {
        type: getType(categoryId),
        category_id: getCategory(categoryId),
      },
    });
  }, [categoryId]);

  const onPageChange = (newPageId) => {
    routerHistory.push(
      getPublicationPageURL(newPageId, categoryId, categorySlug)
    );
  };

  return (
    <>
      <Publications
        publications={data?.results}
        category={category}
        isLoading={isLoading}
        error={error}
        isSmallMd
      />
      {data?.count > config.PUBLICATION_PER_PAGE && (
        <Row>
          <Col className="py-5">
            <Pagination
              pageCount={getPageCount(data?.count || 0)}
              currentPage={pageId - 1 || 0}
              onPageChange={onPageChange}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default CategoryPublications;
