import { Route, Switch } from "react-router-dom";
import routes from "./routes";

import HomePage from "./home/HomePage";
import Error404Page from "./error404/Error404Page";
import PublicationsPage from "./publications/PublicationsPage";
import ArticlePage from "./article/ArticlePage";
import AboutUsPage from "./about_us/AboutUsPage";
import ContactPage from "./contacts/ContactPage";
import PersonnelPage from "./personnel/PersonnelPage";

import EventsListPage from "./events/EventsListPage";
import EventsDetailPage from "./events/EventsDetailPage";

import GalleryPage from "./gallery/GalleryPage";
import GalleryDetailPage from "./gallery/GalleryDetailPage";

import NewsListPage from "./news/NewsListPage";
import NewsDetailPage from "./news/NewsDetailPage";
import AdminPage from "./admin/AdminPage";
import SearchPage from "./search/SearchPage";

const PageRoutesSwitch = () => {
  return (
    <Switch>
      <Route exact path={routes.home} component={HomePage} />
      <Route exact path={routes.sub_article} component={ArticlePage} />
      <Route exact path={routes.article} component={ArticlePage} />
      <Route
        exact
        path={routes.publications_detail_page}
        component={PublicationsPage}
      />
      <Route
        path={routes.publications_category_page}
        component={PublicationsPage}
      />
      <Route
        exact
        path={routes.publications_detail}
        component={PublicationsPage}
      />
      <Route
        exact
        path={routes.publications_section_page}
        component={PublicationsPage}
      />
      <Route path={routes.publications} component={PublicationsPage} />
      <Route exact path={routes.about_us} component={AboutUsPage} />
      <Route exact path={routes.contact} component={ContactPage} />
      <Route exact path={routes.events} component={EventsListPage} />
      <Route exact path={routes.events_year} component={EventsListPage} />
      <Route exact path={routes.event_detail} component={EventsDetailPage} />
      <Route exact path={routes.gallery} component={GalleryPage} />
      <Route exact path={routes.gallery_detail} component={GalleryDetailPage} />
      <Route
        exact
        path={routes.gallery_detail_image}
        component={GalleryDetailPage}
      />
      <Route exact path={routes.news} component={NewsListPage} />
      <Route exact path={routes.news_year} component={NewsListPage} />
      <Route exact path={routes.news_detail} component={NewsDetailPage} />
      <Route exact path={routes.personnel} component={PersonnelPage} />
      <Route exact path={routes.admin} component={AdminPage} />
      <Route exact path={routes.search} component={SearchPage} />
      <Route path="*" component={Error404Page} />
    </Switch>
  );
};

export default PageRoutesSwitch;
