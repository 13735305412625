import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import "assets/styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";
import config from "config";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ErrorSentry from "pages/error_sentry/ErrorSentry";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2b150241d23846949a30ac940eb99125@o410494.ingest.sentry.io/5970030",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
  });
}

ReactGA.initialize(config.GA_TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorSentry}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
