import "./styles.scss";

const GoogleMaps = () => {
  return (
    <div className="google-maps-holder position-relative">
      <div className="square d-none d-md-block"></div>
      <div className="google-iframe position-relative py-2">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2175.760635319598!2d24.111646815977462!3d56.95290218089125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eecfd1bfa23f03%3A0xc0419e26b2ece8ba!2sKalpaka%20bulv%C4%81ris%204%2C%20Centra%20rajons%2C%20R%C4%ABga%2C%20LV-1050!5e0!3m2!1sen!2slv!4v1621203850680!5m2!1sen!2slv"
          title="address"
          width="100%"
          height="268px"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        />
      </div>
    </div>
  );
};

export default GoogleMaps;
