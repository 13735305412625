import { useContext } from "react";
import { useQuery } from "react-query";
import { ConfigContext } from "context";
import { Card } from "react-bootstrap";
import { apiLatestPublications } from "api";
import DottedLoader from "components/dotted-loader/DottedLoader";
import PublicationCardDeck from "components/publication-cards/PublicationCardDeck";
import ErrorAlert from "components/error-alert/ErrorAlert";

const LATEST_PUBLICATION_COUNT = 6;

const Items = () => {
  const { language } = useContext(ConfigContext);

  const { isLoading, error, data } = useQuery(
    ["latestPublications", language, LATEST_PUBLICATION_COUNT],
    () => apiLatestPublications(language, LATEST_PUBLICATION_COUNT)
  );

  if (isLoading) {
    return (
      <Card className="border-0 bg-transparent">
        <DottedLoader className="mb-5" />
      </Card>
    );
  }

  if (error) {
    return <ErrorAlert message={error.message} classNames="mb-5" />;
  }

  return (
    <PublicationCardDeck
      publications={data}
      style={{ minHeight: "20rem" }}
      className="mb-2"
    />
  );
};

export default Items;
