import { Container } from "react-bootstrap";

const BlueWhiteContainer = ({ children, ...rest }) => {
  return (
    <Container fluid className="bc-blue4-gray1 p-0" {...rest}>
      <Container>{children}</Container>
    </Container>
  );
};

export default BlueWhiteContainer;
