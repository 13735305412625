import { GET, POST } from "utils/api";

const API_PUBLICATION_TYPE_PUBLICATION = "publication";
const API_PUBLICATION_TYPE_MAGAZINE = "magazine";

/**
 * Returns tree with all active sections and their subsections
 * @param {string} language
 * @returns {Array}
 */
const apiSections = (language) => {
  return GET("v1/sections", language);
};

/**
 * Returns image src for home carousel
 * @param {string} language
 * @returns {Array}
 */
const apiBanners = (language) => {
  return GET("v1/banners", language);
};

/**
 * Returns latest news
 * @param {string} language
 * @param {number} count
 * @returns {Array}
 */
const apiLatestNews = (language, count) => {
  return GET("v1/news?count=" + count, language);
};

/**
 * Returns latest publications by count with option to filter by category
 * Returned data ordered by DESC
 * @param {string} language
 * @param {number} count
 * @param {string} type
 * @returns {Array}
 */
const apiLatestPublications = (language, count, type) => {
  let queryString = "?count=" + count;

  // Filter by time
  if (
    type &&
    [API_PUBLICATION_TYPE_PUBLICATION, API_PUBLICATION_TYPE_MAGAZINE].indexOf(
      type
    ) > -1
  ) {
    queryString += "&type=" + type;
  }

  return GET("v1/publications" + queryString, language);
};

/**
 * Create newsletter subscription
 * @param {Object} obj
 * @param {Object} obj.data
 * @param {string} obj.language
 * @returns {Object}
 */
const apiNewsletter = ({ data, language }) => {
  return POST("v1/newsletter", data, language);
};

/**
 * Returns latest events
 * @param {string} language
 * @param {number} count
 * @returns {Array}
 */
const apiLatestEvents = (language, count) => {
  return GET("v1/events?count=" + count, language);
};

/**
 * Returns news item by id
 * @param {string} language
 * @param {number} id
 * @returns {Object}
 */
const apiNews = (language, id) => {
  return GET("v1/news/" + id, language);
};

/**
 * Returns event item by id
 * @param {string} language
 * @param {number} id
 * @returns {Object}
 */
const apiEvent = (language, id) => {
  return GET("v1/events/" + id, language);
};

/**
 * Returns galleries
 * @param {string} language
 * @returns {Array}
 */
const apiGalleries = (language) => {
  return GET("v1/galleries", language);
};

/**
 * Returns single gallery by id
 * @param {string} language
 * @param {number} id
 * @returns {Object}
 */
const apiGallery = (language, id) => {
  return GET("v1/galleries/" + id, language);
};

/**
 * Create contact form
 * @param {Object} obj
 * @param {Object} obj.data
 * @param {string} obj.language
 * @returns {Object}
 */
const apiContact = ({ data, language }) => {
  return POST("v1/contact", data, language);
};

/**
 * Returns publication categories
 * @param {string} language
 * @returns {Object}
 */
const apiPublicationCategories = (language) => {
  return GET("v1/publications/categories", language);
};

/**
 * Returns filtered publications ordered by DESC
 * Filtering options:
 *   1. Type
 *   2. Category
 *   3. Count
 *   4. Page
 * @param {Object} obj
 * @param {string} obj.language
 * @param {string} obj.type
 * @param {number} obj.category
 * @param {number} obj.count
 * @param {number} obj.page
 * @returns {(Array|Object)} - Array if "count" is set, otherwise Object
 */
const apiAllPublications = ({ language, type, category, count, page }) => {
  let queryString = "?";

  // Filter by type
  if (
    type &&
    [API_PUBLICATION_TYPE_PUBLICATION, API_PUBLICATION_TYPE_MAGAZINE].indexOf(
      type
    ) > -1
  ) {
    queryString += "&type=" + type;
  }

  // Filter by category
  if (category) {
    queryString += "&category=" + category;
  }

  // Filter by count
  if (count) {
    queryString += "&count=" + count;
  }

  // Filter by page
  if (page) {
    queryString += "&page=" + page;
  }

  return GET("v1/publications" + queryString, language);
};

/**
 * Returns single publication data by ID
 * @param {number} id
 * @param {string} language
 * @returns {Object}
 */
const apiPublication = (language, id) => {
  return GET("v1/publications/" + id, language);
};

/**
 * Returns single article data by ID
 * @param {string} language
 * @param {number} id
 * @returns {Object}
 */
const apiArticle = (language, id, noChildren = false) => {
  if (noChildren) {
    return GET("v1/article/" + id + "/no-children", language);
  }

  return GET("v1/article/" + id, language);
};

/**
 * Returns filtered news ordered by DESC
 * Filtering options:
 *   1. Count
 *   2. Year
 * @param {string} language
 * @param number} count
 * @param {number} year
 * @returns {Array}
 */
const apiNewsList = ({ language, count, year }) => {
  let queryString = "?";

  // Filter by count
  if (count) {
    queryString += "&count=" + count;
  }

  // Filter by year
  if (year) {
    queryString += "&year=" + year;
  }

  return GET("v1/news" + queryString, language);
};

/**
 * Returns filtered events ordered by DESC
 * Filtering options:
 *   1. Count
 *   2. Year
 * @param {string} language
 * @param number} count
 * @param {number} year
 * @returns {Array}
 */
const apiEventList = ({ language, count, year }) => {
  let queryString = "?";

  // Filter by count
  if (count) {
    queryString += "&count=" + count;
  }

  // Filter by year
  if (year) {
    queryString += "&year=" + year;
  }

  return GET("v1/events" + queryString, language);
};

/**
 * Returns list of article children images
 * @param {string} language
 * @param {number} id
 * @returns {Array}
 */
const apiArticleChildrenImages = (language, id) => {
  return GET("v1/article/" + id + "/children-images", language);
};

/**
 *
 * @param {Object} obj
 * @param {Object} obj.data
 * @param {Object} obj.language
 * @returns {Array}
 */
const apiSearch = ({ data, language }) => {
  return POST("v1/search", data, language);
};

export {
  API_PUBLICATION_TYPE_PUBLICATION,
  API_PUBLICATION_TYPE_MAGAZINE,
  apiSections,
  apiBanners,
  apiLatestNews,
  apiLatestPublications,
  apiNewsletter,
  apiLatestEvents,
  apiNews,
  apiEvent,
  apiGalleries,
  apiGallery,
  apiContact,
  apiPublicationCategories,
  apiAllPublications,
  apiPublication,
  apiArticle,
  apiNewsList,
  apiEventList,
  apiArticleChildrenImages,
  apiSearch,
};
