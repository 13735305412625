import { useParams } from "react-router-dom";
import {
  CategoryRow,
  NavigationWrapper,
  CategoryHeadingRow,
} from "components/sub-sections";
import "./styles/SubSections.scss";
import { useState } from "react";

const SubSections = ({
  articles,
  canShowLongTitle,
  moveNavigationUp,
  headingText,
  showToggle,
}) => {
  const { articleId, articleSlug, subArticleId } = useParams();
  const [showItems, setShowItems] = useState(true);

  const getUrl = (article) => {
    return ["/article", articleId, articleSlug, article.id, article.slug].join(
      "/"
    );
  };

  const isSelected = (article) => {
    return article.id === parseInt(subArticleId);
  };

  return (
    <NavigationWrapper
      moveUp={moveNavigationUp}
      handleToggle={setShowItems}
      showToggle={showToggle}
    >
      {headingText && (
        <CategoryHeadingRow text={headingText} showUnderline={showItems} />
      )}
      {articles.map((article, i) => (
        <CategoryRow
          key={article.id}
          handleSelect={() => {}}
          category={article}
          handleGetUrl={getUrl}
          handleIsSelected={isSelected}
          isLast={i === articles.length - 1}
          show={showItems}
        >
          {canShowLongTitle && article.long_title && (
            <div className="tfs-small ml-2 mt-1 pl-2 left-border-gray text-break">
              {article.long_title}
            </div>
          )}
        </CategoryRow>
      ))}
    </NavigationWrapper>
  );
};

export default SubSections;
