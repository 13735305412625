import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { shouldExclude } from "./utils";
import routes from "pages/routes";

const EXCLUDE_ROUTES = [routes.gallery, routes.publications];

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!shouldExclude(EXCLUDE_ROUTES, pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
