import { useState } from "react";
import { GiHamburgerMenu as OpenIcon } from "react-icons/gi";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import "./styles/NavigationWrapper.scss";

const NavigationWrapper = ({
  children,
  moveUp = true,
  showToggle,
  handleToggle,
  bottomSpacing,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div
      className={`bg-white shadow p-3 bg-white rounded ${
        bottomSpacing && "mb-5"
      }`}
      style={moveUp ? { position: "relative", top: "-20px" } : {}}
    >
      {showToggle && (
        <div
          className={`position-relative`}
          onClick={() => {
            handleToggle(!isOpen);
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? (
            <CloseIcon className="sub-section-icon tfs-large" />
          ) : (
            <OpenIcon className="sub-section-icon tfs-large" />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default NavigationWrapper;
