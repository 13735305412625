import { Col, Row, Badge } from "react-bootstrap";

const Tags = ({ tags }) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <Row>
      <Col>
        <hr />
        {tags.map((tag) => (
          <Badge variant="light" className="mr-2">
            {tag.name}
          </Badge>
        ))}
      </Col>
    </Row>
  );
};

export default Tags;
